<template>
	<div class="thg-winner-effect-bg" id="winner_effect_bg" @click="close">
		<canvas id="canvas" style="width: 100%; height: 100%;"></canvas>
		<div class="thg-winner-effect-content-container">
			<div class="thg-winner-effect-content-wrapper">

				<img class="thg-winner-effect-gift-img" src="../images/gift.svg" alt="gift">
				<div class="thg-winner-effect-title">{{ displayText }}</div>
				<div class="thg-winner-effect-title" @click="close"><img style="width: 2rem;cursor: pointer;" src="../images/close.svg" alt="close">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {confettiParticle, getMaskedEmail, maskPhoneNumber} from "@/utils/methods";


let canvas = null;
let context = null;
const maxConfettis = 150;
let particles = [];
let W = window.innerWidth;
let H = window.innerHeight;

export default {
	name: "WinnerEffect",
	props: ['info'],
	mounted() {
		this.setContainerSize()
		canvas = document.getElementById("canvas")
		context = canvas.getContext("2d")
		context.clearRect(0, 0, W, H);
		particles = []
		for (let i = 0; i < maxConfettis; i++) {
			particles.push(new confettiParticle(context));
		}
		canvas.width = W;
		canvas.height = H;
		this.DrawWinnerBg();
	},
	data() {
		return {
			canvas: null,
			animationFrameId: null
		}
	},
	computed: {
		displayText() {
			const winnerEmail = this.info.winnerEmail
			const winnerPhone = this.info.winnerPhoneNum
			if (winnerEmail) {
				if (this.$store.state.userInfo?.email === winnerEmail) {
					return this.$t('恭喜您获得了x!', {prize: this.info.prize})
				}
				return this.$t('恭喜x获得了x!', {winner: getMaskedEmail(winnerEmail), prize: this.info.prize})
			}
			if (this.$store.state.userInfo.phoneNum === winnerPhone) {
				return this.$t('恭喜您获得了x!', {prize: this.info.prize})
			}
			return this.$t('恭喜x获得了x!', {winner: maskPhoneNumber(winnerPhone), prize: this.info.prize})
		}
	},
	methods: {
		setContainerSize() {
			const container = document.getElementById('winner_effect_bg');
			if (container) {
				container.style.width = `${window.innerWidth}px`;
				container.style.height = `${window.innerHeight}px`;
			}
		},
		DrawWinnerBg() {
			const results = [];


			// Magical recursive functional love
			this.animationFrameId = requestAnimationFrame(this.DrawWinnerBg.bind(this));

			context.clearRect(0, 0, W, window.innerHeight);

			for (let i = 0; i < maxConfettis; i++) {
				results.push(particles[i].draw());
			}

			let particle = {};
			let remainingFlakes = 0;
			for (let i = 0; i < maxConfettis; i++) {
				particle = particles[i];

				particle.tiltAngle += particle.tiltAngleIncremental;
				particle.y += (Math.cos(particle.d) + 3 + particle.r / 2) / 2;
				particle.tilt = Math.sin(particle.tiltAngle - i / 3) * 15;

				if (particle.y <= H) remainingFlakes++;

				// If a confetti has fluttered out of view,
				// bring it back to above the viewport and let if re-fall.
				if (particle.x > W + 30 || particle.x < -30 || particle.y > H) {
					particle.x = Math.random() * W;
					particle.y = -30;
					particle.tilt = Math.floor(Math.random() * 10) - 20;
				}
			}

			return results;
		},
		close() {
			if (this.animationFrameId) {
				cancelAnimationFrame(this.animationFrameId);
				this.animationFrameId = null
			}
			this.$emit('close')
		}
	}
}
</script>

<style scoped>
.thg-winner-effect-bg {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(38, 87, 124, 0.9);
	z-index: 100000;
	display: flex;
	justify-content: center;
	align-items: center;
}

h1 {
	position: absolute;
	margin-top: 30vh;
	width: 100%;
	text-align: center;
	font-size: 333%;
	font-family: sans-serif;
	color: grey;
	opacity: 0.5;
}

canvas {
	overflow-y: hidden;
	overflow-x: hidden;
	width: 100%;
	height: 100%;
	margin: 0;
	position: fixed;
}

.thg-winner-effect-content-container {
	width: 100%;
	height: 100%;
	position: relative;
}

.thg-winner-effect-content-wrapper {
	max-width: 80%;
	max-height: 80%;
	overflow: hidden;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.thg-winner-effect-gift-img {
	max-width: 30em;
	max-height: 30em;
}

.thg-winner-effect-title {
	font-size: 2em;
	color: #E55604;
	margin-top: 0.4em;
	text-shadow: 0px 0px 2px #fff, 0 0 1em #E55604, -1px -1px 0.5em #fff;
}

@media (max-width: 1000px) {
	.thg-winner-effect-gift-img {
		width: 30em !important;
	}
	.thg-winner-effect-gift-img {
		max-width: 20em !important;
		max-height: 20em !important;
	}
	.thg-winner-effect-title {
		font-size: 1.6em !important;
	}
}
</style>