import Vuex from 'vuex';

export default new Vuex.Store({
	state: {
		loadingInfo: {
			isLoading: false,
			loadingText: ''
		},
		// isLoading: false,
		isShowModal: false,
		isShowEditAddressModal: false,
		editAddressModalInfo: {
			hideClose: false,
			modalTitle: 'แก้ไขที่อยู่จัดส่ง',
			type: 'address',
			modalIcon: require('../images/address_red.svg')
		},
		confirmModalInfo: {
			display: false,
			title: '',
			content: '',
			showCancel: false,
			showGoldDrop: false,
			afterClose: null
		},
		productPaymentInfo: {
			showPaymentModal: false,
			paymentModalTitle: 'จ่าย',
			productId: '',
			sellId: '',
			productTitle: '',
			maxTicketAmount: '',
			paymentId: ''
		},
		displayMyTicketsPopconfirm: false,
		userInfo: {
			email: null,
			tickets: [],
			deposit: 0,
			address: {
				receiverName: null,
				receiverTitle: null,
				contactCellPhone: null,
				houseNumber: null,
				streetNumber: null,
				district: null,
				province: null,
				postalCode: null
			},
			phoneNum: ''
		},
		isLogin: false,
		promotionProducts: [],
		winnerRollingList: [

		],
		generalProductList: []
	},
	mutations: {
		showLoading(state, payload) {
			state.loadingInfo = payload;
		},
		hideLoading(state) {
			state.loadingInfo = {
				isLoading: false,
				loadingText: ''
			};
		},
		showModal(state) {
			state.isShowModal = true;
		},
		hideModal(state) {
			state.isShowModal = false;
		},
		setIsLogin(state, payload) {
			if (payload) {
				state.isLogin = true;
				state.userInfo = Object.assign(state.userInfo, payload);
			} else {
				state.isLogin = false;
				state.userInfo = {};
			}
		},
		updateTickets(state, payload) {
			state.userInfo = Object.assign(state.userInfo, payload);
		},
		showConfirmModal(state, payload) {
			state.confirmModalInfo = payload
		},
		hideConfirmModal(state) {
			state.confirmModalInfo = {
				display: false,
				title: '',
				content: '',
				showCancel: false,
				showGoldDrop: false
			}
		},
		showMyTicketsPopconfirm(state) {
			state.displayMyTicketsPopconfirm = true
		},
		hideMyTicketsPopconfirm(state) {
			state.displayMyTicketsPopconfirm = false
		},
		updatePromotionProducts(state, payload) {
			state.promotionProducts = payload
		},
		updateWinnerRollingList(state, payload) {
			state.winnerRollingList = payload
		},
		showEditAddressModal(state, payload) {
			state.isShowEditAddressModal = payload
		},
		updateUserAddressInfo(state, payload) {
			state.userInfo = Object.assign(state.userInfo, {address: payload});
		},
		updateShowEditAddressInfo(state, payload) {
			state.editAddressModalInfo = payload
		},
		updateGeneralProductList(state, payload) {
			state.generalProductList = payload
		},
		closePaymentModal(state) {
			state.productPaymentInfo = {
				showPaymentModal: false,
				paymentModalTitle: '',
				productId: '',
				sellId: ''
			}
		},
		showPaymentModal(state, payload) {
			state.productPaymentInfo = Object.assign(state.productPaymentInfo, payload);
		},
		updatePaymentInfo(state, payload) {
			state.productPaymentInfo = Object.assign(state.productPaymentInfo, payload);
		}
	}
});