<template>
	<div class="tgh-payment-method-container">
		<div class="thg-payment-method-title">{{$t('支付方式')}}</div>
		<div class="thg-payment-method-container">
			<div class="thg-payment-icon-container" @click="onPaymentMethodSelected('master_visa')">
				<img :src="selectedMethod==='master_visa' ? require('../images/radio_selected.svg') : require('../images/radio_unselected.svg')" alt="radio unselected"
				     style="width: 1.4em; margin-right: 0.2em;">
				<img src="../images/payment/master_visa.svg" alt="master visa" class="thg-payment-method-icon">
			</div>

			<div class="thg-payment-icon-container" @click="onPaymentMethodSelected('line_pay')">
				<img :src="selectedMethod==='line_pay' ? require('../images/radio_selected.svg') : require('../images/radio_unselected.svg')" alt="radio unselected"
				     style="width: 1.4em; margin-right: 0.2em;">
				<img src="../images/payment/line_pay.svg" alt="master visa" class="thg-payment-method-icon">
			</div>
		</div>
		<div class="thg-payment-amount-title">{{$t('充值金额')}}</div>

		<div class="thg-payment-amount-container">
			<div class="thg-payment-amount-item-container" @click="onPaymentAmountSelected(20)">
				<img :src="selectedAmount===20 ? require('../images/radio_selected.svg') : require('../images/radio_unselected.svg')" alt="radio unselected"
				     style="width: 1.4em; margin-right: 0.2em;">
				<div class="thg-payment-amount-text">￥20</div>
			</div>

			<div class="thg-payment-amount-item-container" @click="onPaymentAmountSelected(50)">
				<img :src="selectedAmount===50 ? require('../images/radio_selected.svg') : require('../images/radio_unselected.svg')" alt="radio unselected"
				     style="width: 1.4em; margin-right: 0.2em;">
				<div class="thg-payment-amount-text">￥50</div>
			</div>
			<div class="thg-payment-amount-item-container" @click="onPaymentAmountSelected(100)">
				<img :src="selectedAmount===100 ? require('../images/radio_selected.svg') : require('../images/radio_unselected.svg')" alt="radio unselected"
				     style="width: 1.4em; margin-right: 0.2em;">
				<div class="thg-payment-amount-text">￥100</div>
			</div>
		</div>

		<div class="thg-payment-btn-container">
			<div class="thg-payment-btn" @click="goToPay">
				{{$t('去支付')}}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "PaymentMethodsContent",
	methods: {
		goToPay() {
			this.$emit('gotopay', {});
		},
		onPaymentMethodSelected(type) {
			this.selectedMethod = type;
		},
		onPaymentAmountSelected(amount) {
			this.selectedAmount = amount;
		}
	},
	data() {
		return {
			selectedMethod: 'master_visa',
			selectedAmount: 20
		}
	}
}
</script>

<style scoped>
.thg-payment-btn {
	background-color: #E55604;
	border-radius: 4px;
	padding: 0.6em 1.6em;
	font-size: 1.2em;
	color: #EBE4D1;
	cursor: pointer;
	user-select: none;
}

.thg-payment-btn-container{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
}
.thg-payment-amount-text {
	font-size: 1.8em;
	color: #4F200D;
	user-select: none;
}

.thg-payment-amount-item-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-right: 2em;
	cursor: pointer;
}

.thg-payment-icon-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-right: 2em;
	cursor: pointer;
}

.tgh-payment-method-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	box-sizing: border-box;
}

.thg-payment-method-title, .thg-payment-amount-title {
	font-size: 1.6em;
	color: #4F200D;
	font-weight: bold;
	width: 100%;
	text-align: start;
	padding: 1em 0;
	box-sizing: border-box;
	user-select: none;
}

.thg-payment-method-container, .thg-payment-amount-container {
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
	padding: 1em 0;
	box-sizing: border-box;
}

.thg-payment-method-icon {
	height: 2em;
	margin-right: 2em;
}

</style>