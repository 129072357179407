<template>
	<div class="thg-product-carousel-container">
		<div class="thg-product-carousel-mask" v-if="productItem.isFinished === true">
			<div class="thg-product-carousel-mask-text">{{$t('已开奖')}}</div>
			<div class="thg-product-carousel-mask-text">{{$t('中奖号码')}}</div>
			<div class="thg-product-carousel-mask-text">{{ productItem.winnerTicketId }}</div>
		</div>
		<div class="thg-carousel-product-title">
			{{ productItem.productTitleTH }}
		</div>
		<div class="thg-carousel-product-img-container">
			<img :src=productItem.imgPath alt="iphone15" class="thg-carousel-product-img">
		</div>
		<div class="thg-carousel-price-container">
			<img src="../images/gold_coin.svg" alt="gold coin" class="thg-carousel-coin"/>
			<div class="thg-carousel-price">{{ productItem.totalTicket }}</div>
			<div class="thg-ticket-purchase-state">
				<div class="thg-ticket-purchase-state-text">
					<div style="margin-right: 0.8em;">{{$t('第几轮', {roundNum: productItem.roundNum})}}</div>
					<img src="../images/ticket.svg" alt="ticket" class="thg-carousel-progress-ticket">
					<div>{{ productItem.currentTicketNum }}/{{ productItem.totalTicket }}</div>
				</div>
				<div class="thg-ticket-purchase-state-bar-container">
					<div class="thg-ticket-purchase-state-bar">

						<div class="thg-ticket-purchase-state-bar-inner" :style="sellPercentage"/>
					</div>
				</div>
			</div>
		</div>

		<div class="thg-carousel-buy-container">
			<img src="../images/ticket.svg" alt="ticket" class="thg-carousel-ticket">
			<div class="thg-carousel-amount-spinner">
				<div class="thg-carousel-amount-spinner-box" @click="minusOne">-</div>
				<div class="thg-carousel-amount-spinner-number">{{ this.ticketAmount }}</div>
				<div class="thg-carousel-amount-spinner-box" @click="addOne">+</div>
			</div>
			<div class="thg-carousel-btn-container">
				<div class="thg-carousel-buy-text-container" @click="onPurchase">
					<img src="../images/shopping_bag.svg" alt="shopping bag" class="thg-carousel-buy-icon">
					<div class="thg-carousel-buy-text">{{ $t('立即购买') }}</div>
				</div>
				<img src="../images/buy_btn.svg" alt="buy" class="thg-carousel-buy-btn">
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "CarouselProductItem",
	props: ['productItem'],
	computed: {
		sellPercentage() {
			return {

				width: this.productItem.currentTicketNum / this.productItem.totalTicket * 100 + '%'

			}
		}
	},
	data() {
		return {
			ticketAmount: 1
		}
	},
	methods: {
		minusOne() {
			if (this.ticketAmount > 1) {
				this.ticketAmount--
			}
		},
		addOne() {
			this.ticketAmount++
		},
		onPurchase(){
			this.$emit('purchaseTicket', this.productItem)
		}
	}
}
</script>

<style scoped>
.thg-product-carousel-mask-text{
	color: #ffffff;
	font-size: 1.4em;
	padding: 0.4em 0;
	box-sizing: border-box;
}
.thg-product-carousel-container {
	display: flex;
	flex-direction: column;
	background-color: rgba(38, 87, 124, 0.2);
	border-radius: 0.6em;
	align-items: start;
	padding: 1.4em 1em;
	box-sizing: border-box;
	margin: 0 0.4em;
	position: relative;
}
.thg-product-carousel-mask {
	position: absolute;
	top: 0;
	left:0;
	width: 100%;
	height:100%;
	background-color: rgba(0, 0, 0, 0.3);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.thg-carousel-product-title {
	color: #4F200D;
	font-size: 1.8em;
	font-weight: bold;
	margin-bottom: 0.4em;
}

.thg-carousel-product-des {
	color: #4F200D;
	font-size: 1em;
	font-weight: bold;
	margin-bottom: 0.4em;
	text-align: start;
}

.thg-carousel-product-img {
	max-height: 100%;
	max-width: 20em;
}

.thg-carousel-product-img-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 24em;
	width: 100%;
	margin: 1em 0;
	box-sizing: border-box;
}

.thg-carousel-price-container {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 0.5em 0;
	box-sizing: border-box;
}

.thg-carousel-buy-container {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 0.5em 0;
	box-sizing: border-box;
}

.thg-carousel-coin {
	width: 2em;
}

.thg-carousel-price {
	color: #E55604;
	font-size: 2em;
	font-weight: bold;
	margin-left: 0.2em;
}

.thg-ticket-purchase-state {
	display: flex;
	flex: 1;
	color: #4F200D;
	font-size: 1.2em;
	flex-direction: column;
	align-items: start;
	padding-left: 1.5em;
	box-sizing: border-box;
}

.thg-ticket-purchase-state-bar-container {
	display: flex;
	position: relative;
	height: 1em;
	width: 100%;
}

.thg-ticket-purchase-state-bar {
	position: absolute;
	background-color: #7E2E00;
	height: 0.2em;
	width: 95%;
	border-radius: 0.1em;
	top: 50%;
	transform: translateY(-50%);
}

.thg-ticket-purchase-state-text {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
}

.thg-ticket-purchase-state-bar-inner {

	background-color: #E55604;
	height: 0.2em;
	width: 80%;
	border-radius: 0.1em;
}

.thg-carousel-ticket {
	width: 2.2em;
	margin-right: 0.2em;
}

.thg-carousel-progress-ticket {
	width: 1.4em;
	margin-right: 0.2em;
}

.thg-carousel-amount-spinner {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #7E2E00;
	font-size: 1.2em;
	font-weight: bold;
}

.thg-carousel-amount-spinner-box {
	width: 2em;
	height: 2em;
	border: 2px solid #7E2E00;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	user-select: none;
}

.thg-carousel-amount-spinner-number {
	border-top: 2px solid #7E2E00;
	border-bottom: 2px solid #7E2E00;
	box-sizing: border-box;
	font-size: 1em;
	min-width: 3.5em;
	height: 2em;
	display: flex;
	align-items: center;
	justify-content: center;
}

.thg-carousel-btn-container {
	position: relative;
	flex: 1;
	height: 3em;
}

.thg-carousel-buy-btn {
	width: 12em;
	top: 50%;
	right: 1em;
	transform: translateY(-50%);
	position: absolute;
	cursor: pointer;
}

.thg-carousel-buy-text-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 50%;
	/* left: 2em; */
	transform: translateY(-50%);
	z-index: 2;
	cursor: pointer;
	right: 5em;
}

.thg-carousel-buy-text {
	color: #EBE4D1;
	font-size: 1.1em;
	cursor: pointer;
	user-select: none;
	padding-bottom: 0.2em;
	width: fit-content;
	white-space: nowrap;
}

.thg-carousel-buy-icon {
	width: 1.2em;
	margin-right: 0.6em;
	cursor: pointer;
}


@media (min-width: 1400px) and (max-width: 1800px) {
	.thg-carousel-product-img {
		max-height: 100%;
		max-width: 35em !important;
	}
}
@media (min-width: 900px) and (max-width: 1400px) {
	.thg-carousel-product-img {
		max-height: 100%;
		max-width: 35em !important;
	}
}
@media (max-width: 900px) {
	.thg-carousel-product-img {
		max-height: 100%;
		max-width: 20em !important;
	}
}

</style>