<template>
	<div class="thg-modal-bg">

			<div class="thg-modal-container">
				<a-spin :tip="$t('加载中...')" size="large" :spinning="isModalSpin"/>
				<div class="thg-modal-title-container">
					<img :src=parameter.modalIcon alt="modal icon" class="thg-modal-icon">
					<div>{{ parameter.modalTitle }}</div>
					<img v-if="parameter.hideClose !== true" src="../images/close.svg" alt="close" class="thg-modal-close" @click="closeModal">
				</div>
				<div class="thg-modal-content-container">
					<PaymentMethodsContent v-if="parameter.type === 'wallet'"/>
					<PurchaseHistoryContent v-if="parameter.type === 'shopHistory'"
					@showModalSpinner="showModalSpinner" @hideModalSpinner="hideModalSpinner"/>
					<AccountInfoContent v-if="parameter.type === 'accountInfo'"/>
					<ManualContent v-if="parameter.type === 'help'"/>
					<LoginContent v-if="parameter.type === 'login'"/>
					<AddressEditContent v-if="parameter.type === 'address'"/>
				</div>

			</div>

	</div>
</template>

<script>
import PaymentMethodsContent from "@/components/PaymentMethodsContent";
import PurchaseHistoryContent from "@/components/PurchaseHistoryContent";
import AccountInfoContent from "@/components/AccountInfoContent";
import ManualContent from "@/components/ManualContent";
import LoginContent from "@/components/LoginContent";
import AddressEditContent from "@/components/AddressEditContent";
import {Spin} from "ant-design-vue";

export default {
	name: "GeneralModal",
	props: ['parameter', 'contentParameter'],
	data() {
		return {
			isModalSpin: false
		}
	},
	components: {
		LoginContent,
		ManualContent,
		AccountInfoContent,
		PurchaseHistoryContent,
		PaymentMethodsContent,
		AddressEditContent,
		'a-spin': Spin
	},
	methods: {
		closeModal() {
			if (this.parameter.type === 'address') {
				this.$store.commit('showEditAddressModal', false)
				return
			}
			this.$store.commit('hideModal');
		},
		showModalSpinner() {
			this.isModalSpin = true;
		},
		hideModalSpinner() {
			this.isModalSpin = false;
		}
	}

}
</script>

<style scoped>
.thg-modal-content-container{
	padding: 2em;
	background-color: #EBE4D1;
	max-height: 75vh;
	overflow-y: scroll;
}

.thg-modal-bg {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
	z-index: 110;
}

.thg-modal-container {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 60%;
	background-color: #EBE4D1;
	z-index: 101;
}

.thg-modal-title-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	color: #E55604;
	padding: 1em;
	box-sizing: border-box;
	font-size: 1.2em;
	border-bottom: 2px solid rgba(229, 86, 4, 0.2);
}

.thg-modal-icon {
	width: 1.2em;
	color: #E55604;
	margin-right: 0.4em;
}

.thg-modal-close {
	width: 1em;
	margin-left: auto;
	opacity: 0.7;
	cursor: pointer;
}
</style>