<template>
	<div class="thg-main-bg" id="main_container">
		<PhoneMenu v-if="isShowMenu"
		           @hideMenu="onHideMenu"
		           @showAccountInfo="onOpenAccountInfo"
		           @showManual="onOpenManual"
		           @showMyTicket="onOpenShopHistory"/>
		<div class="thg-bg-wallpaper"/>
		<WinnerEffect v-if="showWinnerEffect" @close="closeWinnerEffect" :info="winnerEffectInfo"></WinnerEffect>
		<a-popconfirm
			:showCancel="false"
			:disabled="true"
			:open="isShowMyTicketPopconfirm">
			<div class="thg-my-tickets-btn-container" v-if="isShowMyTicketBtn" @click="onOpenShopHistory">
				<img class="thg-my-tickets-btn-img" src="../images/ticket_outline.svg" alt="ticket">
			</div>
			<template #okButton>
				<div class="thg-popconfirm-confirm-btn" @click="onTicketPopConfirm">{{ $t('确定') }}</div>
			</template>
			<template #title>
				<div class="thg-popconfirm-title">{{ $t('在这里查看您的奖券') }}</div>
			</template>
			<template #icon>
			</template>
		</a-popconfirm>
		<GeneralModal v-if="isShowModal" :parameter="this.modalParameter"
		              @closemodal="onCloseModal"/>
		<GeneralModal v-if="isShowEditAddressModal" :parameter="getShowEditAddressInfo"
		              @closemodal="onCloseModal"/>
		<ConfirmModal v-if="isShowConfirmModal"/>
		<PaymentModal v-if="getProductPaymentInfo?.showPaymentModal"/>
		<div class="thg-main-container">
			<div class="thg-header-winner-text-container">
				<div class="winner-header-text">
					<div class="winner-header-text-inner" v-for="(item, index) in getWinnerList" :key="index">
						{{this.getHeaderCongratsText(item)}}
					</div>
				</div>

			</div>

			<div class="thg-main-header">
				<!--				<div class="thg-shop-container">-->
				<!--					<div class="thg-shop-title selected">-->
				<!--						金币商城-->
				<!--					</div>-->
				<!--					<div class="thg-shop-title">-->
				<!--						积分商城-->
				<!--					</div>-->
				<!--				</div>-->
				<div class="thg-web-title"><img src="../images/logo.svg" alt="logo" class="thg-site-logo"/>
					<div>{{ $t('泰会购') }}</div>
				</div>
				<div class="thg-menu-container">
					<div class="thg-menu-text-container" @click="onOpenManual">
						<img src="../images/help.svg" alt="help" class="thg-menu-icon"/>
						<div class="thg-menu-text">{{ $t('规则') }}</div>
					</div>
					<div class="thg-menu-text-container" @click="onOpenShopHistory">
						<img src="../images/ticket.svg" alt="help" class="thg-menu-icon"/>
						<div class="thg-menu-text">{{ $t('奖券') }}</div>
					</div>
					<div class="thg-menu-text-container" @click="onOpenAccountInfo">
						<img src="../images/user.svg" alt="help" class="thg-menu-icon"/>
						<div class="thg-menu-text">{{ $t('我') }}</div>
					</div>

				</div>
				<div class="thg-menu-container-phone">
					<img src="../images/menu.svg" alt="menu" class="thg-menu-img" @click="onShowMenu"/>
				</div>
			</div>
			<div class="thg-promotion-products-container">
				<div class="thg-congrats-section-title-container" style="padding: 0 1em; box-sizing: border-box;">
					<a-divider style="border-color: #E55604; font-size: 1.2em;color: #E55604;">{{ $t('新人大礼包') }}</a-divider>
					<div class="thg-new-comer-prize-des">{{$t('好消息！只要在活动期内注册就送价值50泰铢等值的抽奖劵5张！')}}</div>
				</div>
				<div class="thg-promotion-products-wrapper">
					<PromotionProductItem v-for="item in getPromotionProducts" :key="item.sellId"
					                      :productItem="item"/>
				</div>

			</div>
			<div class="thg-carousel-container">
				<img src="../images/banner.jpg" alt="banner" style="width: 100%;">
<!--				<Carousel arrows>-->
<!--					<template #prevArrow>-->
<!--						<div class="custom-slick-arrow" style="left: 10px; z-index: 1">-->
<!--							<left-circle-outlined/>-->
<!--						</div>-->
<!--					</template>-->
<!--					<template #nextArrow>-->
<!--						<div class="custom-slick-arrow" style="right: 10px">-->
<!--							<right-circle-outlined/>-->
<!--						</div>-->
<!--					</template>-->
<!--					<div><h3>1</h3></div>-->
<!--					<div><h3>2</h3></div>-->
<!--					<div><h3>3</h3></div>-->
<!--					<div><h3>4</h3></div>-->
<!--				</Carousel>-->
			</div>
			<div class="thg-product-carousel-section">
				<div class="thg-product-carousel-section-wrapper">
					<div class="thg-main-section-title">
						<div>{{$t('热门商品')}}</div>
						<div class="thg-main-section-title-check-more-section" style="display: none;">
							<div>{{$t('查看更多商品')}}</div>
							<img src="../images/more_products.svg" alt="more products"
							     style="width: 0.8em;padding-left: 0.4em;">
						</div>
					</div>
					<div class="thg-product-carousel-row-container">
						<CarouselProductItem v-for="item in getGeneralProductList"
						                     :productItem="item"
						                     :key="item.productId"
						                     @purchaseTicket="onPurchaseTicket"/>
					</div>
				</div>

			</div>
			<div class="thg-congrats-section">
				<div class="thg-congrats-section-title-container">
					<a-divider style="border-color: #E55604; font-size: 1.2em;color: #E55604;">{{$t('恭喜中奖会员')}}</a-divider>
				</div>

				<div class="thg-winner-table">
					<div class="thg-winner-header-row">
						<div class="thg-winner-header-cell">
							{{$t('奖品')}}
						</div>
						<div class="thg-winner-header-cell">
							{{$t('期数')}}
						</div>
						<div class="thg-winner-header-cell">
							{{$t('奖券编号')}}
						</div>
						<div class="thg-winner-header-cell">
							{{$t('会员邮箱')}}
						</div>
						<div class="thg-winner-header-cell">
							{{$t('开奖时间')}}
						</div>
					</div>

					<div class="thg-winner-table-row" v-for="item in getWinnerList" :key="item.prizeId">

						<div class="thg-winner-table-cell">
							{{ item.productTitleTH }}
						</div>
						<div class="thg-winner-table-cell">
							{{$t('第几轮', {roundNum: item.roundNum})}}
						</div>
						<div class="thg-winner-table-cell">
							{{ item.ticketId }}
						</div>
						<div class="thg-winner-table-cell">
							{{ this.getMaskedEmailDisplay(item.email) }}
						</div>
						<div class="thg-winner-table-cell">
							{{ item.generatedTimeString }}
						</div>
					</div>
				</div>
				<div class="thg-winner-table-phone">
					<div class="thg-winner-table-phone-cell-wrapper"  v-for="item in getWinnerList" :key="item.prizeId">
						<div class="thg-winner-table-phone-cell">{{$t('奖品：')}}{{ item.productTitleTH }}</div>
						<div class="thg-winner-table-phone-cell">
							{{$t('第几轮', {roundNum: item.roundNum})}}
						</div>
						<div class="thg-winner-table-phone-cell">
							{{$t('奖券号：')}}{{ item.ticketId }}
						</div>
						<div class="thg-winner-table-phone-cell">
							{{$t('会员邮箱：')}}{{ this.getMaskedEmailDisplay(item.email) }}
						</div>
						<div class="thg-winner-table-phone-cell">
							{{$t('时间：')}}{{ item.generatedTimeString }}
						</div>
					</div>
				</div>
			</div>

		</div>

	</div>

</template>

<script>
import {LeftCircleOutlined, RightCircleOutlined} from '@ant-design/icons-vue'
import {Carousel, Divider, message, Popconfirm} from 'ant-design-vue'
import CarouselProductItem from "@/components/CarouselProductItem";
import GeneralModal from "@/components/GeneralModal";
import ConfirmModal from "@/components/ConfirmModal";
import PromotionProductItem from "@/components/PromotionProductItem";
import WinnerEffect from "@/components/WinnerEffect";
import PhoneMenu from "@/components/PhoneMenu";
import PaymentModal from "@/components/PaymentModal";
import {getPromotionProducts, getUserInfoBySessionId, socketConnect, global, getMaskedEmail, getWinnerList, getGeneralProducts} from "@/utils/methods";

export default {
	name: "MainPage",
	components: {
		// RightCircleOutlined,
		// LeftCircleOutlined,
		// Carousel,
		CarouselProductItem,
		'a-divider': Divider,
		GeneralModal,
		ConfirmModal,
		'a-popconfirm': Popconfirm,
		PromotionProductItem,
		WinnerEffect,
		PhoneMenu,
		PaymentModal
	},
	data() {
		return {
			productList: [
				{
					title: '1.8克金条',
					imgPath: require('../images/products/gold.png')
				},
				{
					title: 'iPhone 14 Pro 128G',
					imgPath: require('../images/products/iphone14pro.png')
				},
				{
					title: 'iPhone 14 Pro Max 128G',
					imgPath: require('../images/products/iphone14pro.png')
				},
				{
					title: 'HUAWEI Nova 9',
					imgPath: require('../images/products/huaweinova9.png')
				},
				{
					title: 'HUAWEI Nova 8i',
					imgPath: require('../images/products/huaweinova8i.png')
				},
				{
					title: 'HUAWEI Nova Y70',
					imgPath: require('../images/products/huaweinovay70.png')
				},
				{
					title: 'Xiaomi POCO C65',
					imgPath: require('../images/products/xiaomipococ65.png')
				},
				{
					title: 'Xiaomi Redmi 13C',
					imgPath: require('../images/products/redmi13c.png')
				},
				{
					title: 'OPPO A31 6G+128G',
					imgPath: require('../images/products/oppoa31.png')
				},
				{
					title: 'OPPO A17',
					imgPath: require('../images/products/oppoa17.png')
				},
				{
					title: 'Xiaomi Redmi A2+',
					imgPath: require('../images/products/redmia2+.png')
				}
			],
			winnerList: [
				{
					prize: 'XBox One',
					season: '15',
					ticketNumber: 'SN15-XBOXONE-00188',
					email: '123123@qq.com',
					drawTime: '2024-01-12 12:00:00',
					prizeId: 'd8ec1aeb-4f15-40af-b532-feeb32cea35b'
				},
				{
					prize: 'iPhone 15 Pro Max',
					season: '98',
					ticketNumber: 'SN98-IPHONE15PROMAX-00968',
					email: '56434678768@qq.com',
					drawTime: '2024-01-11 12:36:04',
					prizeId: 'd2a38054-1e06-4f46-bb74-f16a99493d79'
				},
				{
					prize: 'SK II 套装',
					season: '98',
					ticketNumber: 'SN98-SKIISET-00668',
					email: '56456728768@qq.com',
					drawTime: '2024-01-11 12:36:04',
					prizeId: '48751b28-aa1c-4928-9f50-8fc3621fb8fb'
				},
			],
			showWinnerEffect: false,
			winnerEffectInfo: {
				winner: '',
				prize: ''
			},
			// openModal: false,
			modalParameter: {
				modalTitle: '',
				modalIcon: null,
				type: ''
			},
			isShowMenu: false,
			winnerListHeader: []
		}
	},
	computed: {
		isShowModal() {
			return this.$store.state.isShowModal;
		},
		isShowConfirmModal() {
			return this.$store.state.confirmModalInfo.display;
		},
		isShowEditAddressModal() {
			return this.$store.state.isShowEditAddressModal;
		},
		getShowEditAddressInfo () {
			return this.$store.state.editAddressModalInfo;
		},
		isShowMyTicketBtn() {
			return this.$store.state.isLogin;
		},
		isShowMyTicketPopconfirm() {
			return this.$store.state.displayMyTicketsPopconfirm
		},
		getPromotionProducts() {
			return this.$store.state.promotionProducts;
		},
		getWinnerList() {
			return this.$store.state.winnerRollingList;
		},
		getGeneralProductList () {
			return this.$store.state.generalProductList;
		},
		getProductPaymentInfo () {
			return this.$store.state.productPaymentInfo
		}
	},
	async created() {
		const tmp = this.productList
		this.productList = []
		this.productList = tmp.sort((a, b) =>
		{
			if (a.priority) {return -1}
			if (b.priority) {return 1}
			return a.price - b.price
		})
		// let languages = navigator.languages;
		// let toSet = languages[0]
		// if (toSet.toLowerCase().indexOf('zh') > -1) {
		// 	this.$i18n.locale = 'zh'
		// } else {
			this.$i18n.locale = 'th'
		// }
		// 监听事件数据的变化
		this.$store.watch(
			(state) => state.isShowModal,
			(newData) => {
				// 处理事件数据的变化
			}
		);
		this.$store.watch(
			(state) => state.confirmModalInfo,
			(newData) => {
				// 处理事件数据的变化
			}
		);
		this.$store.watch(
			(state) => state.displayMyTicketsPopconfirm,
			(newData) => {
				// 处理事件数据的变化
			}
		);
		// this.$store.watch(
		// 	(state) => state.productPaymentInfo,
		// 	(newData) => {
		// 		// 处理事件数据的变化
		// 	}
		// );

		this.$store.commit('showLoading', {
			isLoading: true,
			loadingText: this.$t('加载中...')
		})
		const result = await getUserInfoBySessionId()
		this.$store.commit('hideLoading');
		if (!result || result?.code !== 200) {
			this.$store.commit('setUserLogin', false);
		} else {
			if (result?.code === 200 && result?.msg) {
				this.$store.commit('setIsLogin', result?.msg);
				if (!result?.msg?.address || !result?.msg?.address?.receiverName) {
					this.$store.commit('updateShowEditAddressInfo', {
						hideClose: true,
						modalTitle: this.$t('请输入您接收奖品的地址'),
						type: 'address',
						modalIcon: require('../images/address_red.svg')
					})
					this.$store.commit('showEditAddressModal', true)
				}
			}
		}


		const generalProductsResponse = await getGeneralProducts()
		if (generalProductsResponse?.code === 200 && generalProductsResponse?.msg?.generalProducts) {
			const generalProductList = []
			for (const item of generalProductsResponse?.msg?.generalProducts) {
				this.productList.forEach((product) => {
					if (item.productTitle === product.title) {
						item.imgPath = product.imgPath
						generalProductList.push(item)
					}
				})
			}

			generalProductList.sort((a, b) =>
			{
				if (a.priority) {return -1}
				if (b.priority) {return 1}
				return a.totalTicket - b.totalTicket
			})
			this.$store.commit('updateGeneralProductList', generalProductList)
		}
	},
	async mounted() {

		this.setContainerSize();
		window.addEventListener('resize', this.setContainerSize); // 如果你想要在窗口调整大小时更新
		const result = await getPromotionProducts()
		if (!result || result?.code !== 200) {
			return
		}

		if (result?.code === 200 && result?.msg) {
			this.$store.commit('updatePromotionProducts', result?.msg?.promotionProducts);
		}
		const container = document.querySelector(".thg-header-winner-text-container"); // 容器元素
		const textNode = document.querySelector(".thg-header-winner-text-container > .winner-header-text"); // 文字元素
		const textWidth = textNode.offsetWidth; // 获取文字元素宽度
		const containerWidth = container.offsetWidth; // 获取容器宽度
		let startPosition = containerWidth; // 文字开始位置设为容器宽度，这样起始时文字将从右侧进入
		let currentPos = startPosition;

		const loop = () => {
			if (currentPos <= -textWidth) {
				// 如果文字完全离开视图，重置到开始位置
				currentPos = startPosition;
			} else {
				// 否则，继续向左移动
				currentPos -= 1;
			}

			textNode.style.transform = `translateX(${currentPos}px)`;

			window.requestAnimationFrame(loop); // 动画递归调用
		};

		loop(); // 启动动画

		if (!global.socketConnection) {
			const socketConn = socketConnect()

			socketConn.on('connect_error', (error) => {
				// 连接失败时的操作
				console.log(error)
			});

			socketConn.on('reconnect_attempt', () => {
				// 尝试重新连接时的操作

				console.log(`reconnect_attempt`);
			});

			socketConn.on('disconnect', (reason) => {
				console.log(`disconnect: ${reason}`);
				// reason 参数可以告诉你是因为什么原因断开连接的，例如 "io server disconnect", "io client disconnect", "ping timeout", "transport close", 等。
			});

			socketConn.on('connect', () => {
				// 成功连接时的操作
				console.log('socket connected')
				// socketConn.emit('message', { text: 'Hello server!' });
			});
			socketConn.on('payment_result', (data) => {
				if (data.isSuccess === true && data.paymentId === this.$store.state.productPaymentInfo?.paymentId) {
					this.$store.commit('closePaymentModal')
					this.$store.commit('updatePaymentInfo', {paymentId: null})
					message.success(this.$t('支付成功！'))
					return
				}
				message.error(this.$t('支付失败！'))

			});
			socketConn.on('update_general_products', (data) => {
				if (data.generalProductList) {
					const generalProductList = []
					for (const item of data.generalProductList) {
						this.productList.forEach((product) => {
							if (item.productTitle === product.title) {
								item.imgPath = product.imgPath
								generalProductList.push(item)
							}
						})
					}

					generalProductList.sort((a, b) =>
					{
						if (a.priority) {return -1}
						if (b.priority) {return 1}
						return a.totalTicket - b.totalTicket
					})
					this.$store.commit('updateGeneralProductList', generalProductList)
					// const generalProductList = []
					// for (const item of data.generalProductList) {
					// 	this.productList.forEach((product) => {
					// 		if (item.productTitle === product.title) {
					// 			item.imgPath = product.imgPath
					// 			generalProductList.push(item)
					// 		}
					// 	})
					// }
					// this.$store.commit('updateGeneralProductList', generalProductList)
				}
			});

			socketConn.on('congrats_winner', (data) => {
				console.log(data)
				this.winnerEffectInfo = {
					winnerEmail: data?.winnerEmail,
					winnerPhoneNum: data?.phoneNum,
					prize: data?.prize
				}
				this.showWinnerEffect = true
			});

			socketConn.on('update_promotion_products', (data) => {
				console.log(data)
				this.$store.commit('updatePromotionProducts', data?.promotionProductList);
			});
			socketConn.on('broadcast_winner_list', (data) => {
				console.log(data)
				this.$store.commit('updateWinnerRollingList', data?.winnerList);
			});
		}
		const getWinnerListResult = await getWinnerList()
		if (getWinnerListResult.code === 200) {
			this.$store.commit('updateWinnerRollingList', getWinnerListResult?.msg?.winnerList);
			this.winnerListHeader = getWinnerListResult?.msg?.winnerListHeader
		}
	},
	beforeUnmount() {
		window.removeEventListener('resize', this.setContainerSize); // 清理事件监听器
	},
	methods: {
		setContainerSize() {
			const container = document.getElementById('main_container');
			if (container) {
				container.style.width = `${window.innerWidth}px`;
				container.style.height = `${window.innerHeight}px`;
			}
		},
		getHeaderCongratsText(item) {
			// return `恭喜会员 "${getMaskedEmail(item.email)}" 获得 第${item.roundNum}期 "${item.productTitle}"。`
			return this.$t('恭喜会员 x 获得 第x期 x', {email: getMaskedEmail(item.email), roundNum: item.roundNum, productTitle: item.productTitleTH})
		},
		getMaskedEmailDisplay(email) {
			return getMaskedEmail(email)
		},
		onCloseModal() {
			// this.openModal = false
			this.$store.commit('hideModal');
		},
		onOpenWalletModal() {
			if (!this.$store.state.isLogin) {
				this.onOpenLoginModal()
				message.warning(this.$t('登录后继续操作'));
				return
			}
			this.modalParameter = {
				modalTitle: this.$t('会员充值'),
				modalIcon: require('../images/wallet_red.svg'),
				type: 'wallet'
			}
			// this.openModal = true
			this.$store.commit('showModal');
		},
		onOpenShopHistory() {
			// this.showWinnerEffect = true
			if (!this.$store.state.isLogin) {
				this.onOpenLoginModal()
				message.warning(this.$t('登录后继续操作'));
				return
			}
			this.modalParameter = {
				modalTitle: this.$t('我的奖券'),
				modalIcon: require('../images/ticket_red.svg'),
				type: 'shopHistory'
			}
			// this.openModal = true
			this.$store.commit('showModal');
		},
		onOpenAccountInfo() {
			if (!this.$store.state.isLogin) {
				this.onOpenLoginModal()
				message.warning(this.$t('登录后继续操作'));
				return
			}
			this.modalParameter = {
				modalTitle: this.$t('会员信息'),
				modalIcon: require('../images/user_red.svg'),
				type: 'accountInfo'
			}
			this.$store.commit('showModal');
		},
		onOpenManual() {

			if (!this.$store.state.isLogin) {
				this.onOpenLoginModal()
				message.warning(this.$t('登录后继续操作'));
				return
			}
			this.modalParameter = {
				modalTitle: this.$t('规则说明'),
				modalIcon: require('../images/help_red.svg'),
				type: 'help'
			}
			this.$store.commit('showModal');
		},
		onOpenLoginModal() {
			this.modalParameter = {
				modalTitle: this.$t('会员登录'),
				modalIcon: require('../images/login.svg'),
				type: 'login'
			}

			this.$store.commit('showModal');
		},
		onTicketPopConfirm() {
			this.$store.commit('hideMyTicketsPopconfirm');
		},
		closeWinnerEffect() {
			this.showWinnerEffect = false
		},
		onShowMenu() {
			this.isShowMenu = true
		},
		onHideMenu() {
			this.isShowMenu = false
		},
		onPurchaseTicket(productItem) {
			if (!this.$store.state.isLogin) {
				this.onOpenLoginModal()
				message.warning(this.$t('请登录后购买'));
				return
			}
			this.$store.commit('showPaymentModal', {
				showPaymentModal: true,
				paymentModalTitle: '选择购买的奖券数量',
				productId: productItem.productId,
				sellId: productItem.sellId,
				productTitle: productItem.productTitleTH,
				maxTicketAmount: productItem.totalTicket - productItem.currentTicketNum
			})
		}

	}
}
</script>

<style scoped>
.thg-promotion-products-container {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	padding: 1em 0;
	width: 100%;
}

.thg-promotion-products-wrapper {
	display: grid;
	grid-template-columns: repeat(5, 18vw) !important;
	grid-gap: 0.8em;
}

.thg-winner-header-cell {
	display: table-cell;
	padding: 0.5em 1em;
	border-bottom: 3px solid #4F200D;
	color: #4F200D;
	font-weight: bold;
	box-sizing: border-box;
	font-size: 1.6em;
}

.thg-winner-table-cell {
	display: table-cell;
	padding: 0.5em 1em;
	border-bottom: 1px solid #4F200D;
	color: #4F200D;
	text-align: start;
	font-size: 1.6em;
}

.thg-winner-header-row {
	display: table-row;
}

.thg-winner-table-row {
	display: table-row;
}

.thg-winner-table {
	display: table;
	width: 100%;
	background-color: rgba(79, 32, 13, 0.2);
}

.thg-winner-table-phone {
	display: none;
}

.thg-congrats-section-title-container {
	width: 100%;
}

.thg-congrats-section-title-text {

	font-size: 1.2em;
	color: #E55604;
}

.thg-congrats-section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	width: 100%;
	padding: 1em 3em;
}

.thg-main-section-title-check-more-section {
	font-size: 0.8em;
	color: #E55604;
	user-select: none;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.thg-product-carousel-section {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	width: 100%;
}

.thg-product-carousel-section-wrapper {
	display: flex;
	flex-direction: column;
	padding: 2em 0;
	box-sizing: border-box;
	width: fit-content;
}

.thg-main-section-title {
	font-size: 2em;
	color: #364d79;

	text-align: start;
	padding-bottom: 0.4em;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-left: 0.2em;
}

.thg-product-carousel-row-container {
	width: 100%;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	display: grid;
	grid-template-columns: repeat(4, 23vw); /* 创建三列，每列宽度为30%的视口宽度 */
	grid-gap: 1em;
}

@media (min-width: 1400px) and (max-width: 1800px) {
	.thg-product-carousel-row-container {
		grid-template-columns: repeat(3, 30vw) !important; /* 创建三列，每列宽度为30%的视口宽度 */

	}

	.thg-promotion-products-wrapper {
		display: grid;
		grid-template-columns: repeat(3, 30vw) !important;
		grid-gap: 1em;
	}
}

@media (min-width: 900px) and (max-width: 1400px) {
	.thg-product-carousel-row-container {
		grid-template-columns: repeat(2, 45vw) !important; /* 创建三列，每列宽度为30%的视口宽度 */
	}

	.thg-promotion-products-wrapper {
		display: grid;
		grid-template-columns: repeat(3, 30vw) !important;
		grid-gap: 1em;
	}
}

@media (max-width: 900px) {
	.thg-product-carousel-row-container {
		grid-template-columns: repeat(1, 90vw) !important; /* 创建三列，每列宽度为30%的视口宽度 */
	}

	.thg-my-tickets-btn-container {
		bottom: 2em !important;
		right: 2em !important;
	}

	.thg-promotion-products-wrapper {
		display: grid;
		grid-template-columns: repeat(3, 30vw) !important;
		grid-gap: 0.4em;
	}

	.thg-menu-img {
		display: block !important;
	}

	.thg-winner-table {
		display: none !important;
	}

	.thg-winner-table-phone {
		display: block !important;
		width: 100%;
		padding: 0;
		box-sizing: border-box;
	}

	.thg-winner-table-phone-cell-wrapper {
		background-color: rgba(79, 32, 13, 0.2);
		margin: 1em 0;
		width: 100%;
		box-sizing: border-box;
		padding: 1em 0;
		border-radius: 4px;
	}

	.thg-winner-table-phone-cell {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: start;
		padding: 0;
		color: #4F200D;
		text-align: start;
		margin: 0.5em 1em;
		font-size: 1.2em;
	}


	.thg-new-comer-prize-des{
		font-size: 1.6em !important;
	}


	.winner-header-text{
		font-size: 1.6em;
	}


	.thg-carousel-container {
		padding:0.4em !important;
	}

}

.thg-main-container {
	z-index: 3;
	overflow-x: hidden;
	overflow-y: scroll;
}

.thg-shop-container {
	display: flex;
	flex-direction: row;
	color: #E55604;
	height: 100%;
}

.thg-shop-title {

	display: flex;
	align-items: center; /* 垂直居中 */
	height: 100%;
	margin-right: 0.6em;
}

.thg-carousel-container {
	width: 100%;
	padding:1em 4em;
	box-sizing: border-box;
}

.thg-shop-title.selected {
	border-bottom: 2px solid #E55604;
}

.thg-main-header {
	background: #EBE4D1;
	font-size: 1em;
	width: 100%;
	display: flex;
	flex-direction: row;
	position: relative;
	justify-content: end;
	align-items: center;
	padding: 0 0.5em;
	box-sizing: border-box;
	border-bottom: 2px solid rgba(229, 86, 4, 0.25);
	height: 4.6em;
}

.thg-web-title {
	color: #E55604;
	font-size: 2.2em;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.thg-site-logo {
	width: 1.2em;
	margin-right: 0.2em;
}

.thg-main-bg {
	background: #EBE4D1;
	position: fixed;
	/*width: 100vw;*/
	/*height: 100vh;*/
	top: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	padding-top: 2.6em;
	box-sizing: border-box;
}

.thg-menu-container {
	display: flex;
	flex-direction: row;
	justify-content: end;
	align-items: end;
}

.thg-menu-icon {
	height: 1.8em;
	padding: 0.5em 0.2em;
	cursor: pointer;
}

.thg-bg-wallpaper {
	position: fixed;
	width: 400vw;
	height: 400vh;
	background-image: url("../images/bg.svg");
	background-repeat: repeat;
	background-size: 40em;
	overflow: hidden;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(45deg);
	opacity: 0.05;
	z-index: 2;
}

.thg-my-tickets-btn-container {
	position: fixed;
	width: 5em;
	height: 5em;
	background-color: rgba(54, 77, 121, 0.2);
	border-radius: 50%;
	bottom: 8em;
	right: 8em;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	z-index: 10;
}

.thg-my-tickets-btn-img {
	width: 4em;
}

/* For demo */
:deep(.slick-slide) {
	text-align: center;
	height: 160px;
	line-height: 160px;
	background: #364d79;
	overflow: hidden;
}

:deep(.slick-arrow.custom-slick-arrow) {
	width: 25px;
	height: 25px;
	font-size: 25px;
	color: #fff;
	background-color: rgba(31, 45, 61, 0.11);
	transition: ease all 0.3s;
	opacity: 0.3;
	z-index: 1;
}

:deep(.slick-arrow.custom-slick-arrow:before) {
	display: none;
}

:deep(.slick-arrow.custom-slick-arrow:hover) {
	color: #fff;
	opacity: 0.5;
}

:deep(.slick-slide h3) {
	color: #fff;
}

.thg-menu-img {
	width: 1.8em;
	display: none;
}

.thg-header-winner-text-container{
	background-color: #E55604;
	color: #ffffff;
	display: flex;
	overflow: hidden;
	flex-direction: row;
	width: 100%;
	padding: 0.2em 0;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	height: 2.6em;
	box-shadow: 0 0 4px rgba(180, 66, 1, 0.8);
}
.winner-header-text{
	padding: 0 5px;
	display: flex;
	flex-direction: row;
	font-size: 2em;
}
.winner-header-text-inner{
	padding-right: 1.5em;
	white-space:nowrap;
}

.thg-new-comer-prize-des{
	font-size: 2.2em;
	color: #4F200D;
	width: 100%;
	padding-bottom: 1em;
	box-sizing: border-box;
	animation: color-blink 1s linear infinite;
}

@keyframes blink {
	0%, 100% { opacity: 1; }
	50% { opacity: 0; }
}

.blinking-text {
	animation: blink 1s linear infinite;
}
@keyframes color-blink {
	0%, 100% { color: blue; }
	50% { color: red; }
}


/*@keyframes blink {*/
/*	0%, 20%, 40%, 60%, 100% { text-shadow: none; }*/
/*	10%, 50% { text-shadow: 0 0 2px #fff, 0 0 1em #E55604, -1px -1px 0.8em #fff;  }*/
/*}*/

.thg-menu-text-container{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-right: 0.4em;
	box-sizing: border-box;
}

.thg-menu-text{

	color: #4F200D;
}

/*.thg-header-winner-text-container{*/
/*	background-color: #E55604;*/
/*	color: #ffffff;*/
/*	font-size: 1em;*/
/*	padding: 0.2em 0;*/
/*	box-sizing: border-box;*/
/*	display: flex;*/
/*	flex-direction: row;*/
/*}*/

/*@keyframes text-scroll {*/
/*	0% {*/
/*		left: 100%;*/
/*	}*/
/*	25% {*/
/*		left: 50%;*/
/*	}*/
/*	50% {*/
/*		left: 0%;*/
/*	}*/
/*	75% {*/
/*		left: -50%;*/
/*	}*/
/*	100% {*/
/*		left: -100%;*/
/*	}*/
/*}*/
/*.winner-header-text {*/
/*	position: relative;*/
/*	animation: text-scroll 5s linear infinite;*/
/*	padding-right: 0.5em;*/
/*	box-sizing: border-box;*/
/*	white-space: nowrap;*/
/*}*/

</style>