<template>
	<div class="thg-payment-modal-bg">

		<div class="thg-payment-modal-container">
			<div class="thg-payment-modal-title-container">
				<img src="../images/wallet_red.svg" alt="modal icon" class="thg-payment-modal-icon">
				<div>{{$t('请确认购买的奖券数')}}</div>
				<img src="../images/close.svg" alt="close" class="thg-payment-modal-close" @click="closePaymentModal">
			</div>
			<div class="thg-payment-modal-content-container">
				<div class="thg-payment-modal-spinner-container" v-if="showSpinnerMask">

					<a-spin :tip="$t('支付验证中...')" size="large" :spinning="true"/>
				</div>
				<div class="thg-payment-modal-content-wrapper">
					<div class="thg-payment-modal-product-title">{{$t('商品名称：')}} {{ getPaymentModalInfo.productTitle }}</div>
					<div class="thg-payment-modal-product-title">{{$t('奖券数量：')}}</div>
					<div class="thg-payment-modal-set-amount-container">
						<div class="thg-carousel-amount-spinner">
							<div class="thg-carousel-amount-spinner-box" @click="minusOne">-</div>
							<div class="thg-carousel-amount-spinner-number">{{ this.ticketAmount }}</div>
							<div class="thg-carousel-amount-spinner-box" @click="addOne">+</div>
						</div>
					</div>
					<div class="thg-payment-modal-confirm-container">
						<div class="thg-payment-modal-confirm-btn" @click="confirmPayment">
							 {{$t('确定')}}
						</div>
					</div>
				</div>
			</div>

		</div>

	</div>
</template>

<script>
import {getPaymentUrl} from "@/utils/methods";
import {message} from "ant-design-vue";
import {Spin} from "ant-design-vue";

export default {
	name: "PaymentModal",
	components: {
		'a-spin': Spin
	},
	data() {
		return {
			ticketAmount: 1,
			showSpinnerMask: false
		}
	},
	mounted() {
		this.showSpinnerMask = false
	},
	computed: {
		getPaymentModalInfo() {
			return this.$store.state.productPaymentInfo
		}
	},
	methods: {
		closePaymentModal() {
			this.$store.commit('closePaymentModal')
		},
		minusOne() {
			if (this.ticketAmount <= 1) {
				this.ticketAmount = 1;
				return
			}
			this.ticketAmount = this.ticketAmount -1
		},
		addOne() {
			if (this.ticketAmount >= this.maxTicketAmount) {
				this.ticketAmount = this.maxTicketAmount;
				return
			}
			this.ticketAmount = this.ticketAmount + 1
		},
		async confirmPayment(){
			// this.$store.commit('showLoading', {
			// 	isLoading: true,
			// 	loadingText: '支付验证中...'
			// })
			this.showSpinnerMask = true
			const paymentInfo = this.$store.state.productPaymentInfo
			// this.$store.commit('hideLoading')
			const result = await getPaymentUrl({
				sellId: paymentInfo.sellId,
				ticketAmount: this.ticketAmount
			})


			if (result?.code === 200) {

				if (result?.msg?.payUrl) {
					const paymentId = result?.msg?.paymentId
					this.$store.commit('updatePaymentInfo', {paymentId})
					const windowReference = window.open('', '_blank');
					windowReference.location = result?.msg?.payUrl;
				} else if (result?.msg?.payByDeposit === true) {

					this.showSpinnerMask = false
					message.success(this.$t('余额支付成功'));
					this.$store.commit('closePaymentModal')
				}

			} else {
				this.showSpinnerMask = false
				message.error(this.$t('获取支付链接失败，请稍候重试。'));
			}
		}
	}
}
</script>

<style scoped>

.thg-payment-modal-spinner-container{
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.2);
	position:absolute;z-index: 102;
	top: 0;
	left: 0;
}

.thg-payment-modal-content-wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: start;
}

.thg-payment-modal-content-container {
	padding: 2em;
	background-color: #EBE4D1;
	max-height: 75vh;
	overflow-y: auto;
	position: relative;
}

.thg-payment-modal-bg {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
	z-index: 110;
}

.thg-payment-modal-container {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 60%;
	background-color: #EBE4D1;
	z-index: 101;
}

.thg-payment-modal-title-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	color: #E55604;
	padding: 1em;
	box-sizing: border-box;
	font-size: 1.2em;
	border-bottom: 2px solid rgba(229, 86, 4, 0.2);
}

.thg-payment-modal-icon {
	width: 1.2em;
	color: #E55604;
	margin-right: 0.4em;
}
.thg-payment-modal-product-title{
	font-size: 1.4em;
	color: #7E2E00;
	margin-bottom: 1em;
}


.thg-payment-modal-close {
	width: 1em;
	margin-left: auto;
	opacity: 0.7;
	cursor: pointer;
}

@media (max-width: 900px) {
	.thg-payment-modal-container {
		width: 90% !important;
	}

}

.thg-carousel-amount-spinner {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #7E2E00;
	font-size: 1.2em;
	font-weight: bold;
}

.thg-carousel-amount-spinner-box {
	width: 2em;
	height: 2em;
	border: 2px solid #7E2E00;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	user-select: none;
}

.thg-carousel-amount-spinner-number {
	border-top: 2px solid #7E2E00;
	border-bottom: 2px solid #7E2E00;
	box-sizing: border-box;
	font-size: 1em;
	min-width: 3.5em;
	height: 2em;
	display: flex;
	align-items: center;
	justify-content: center;
}

.thg-payment-modal-set-amount-container{
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
}


.thg-payment-modal-confirm-container{
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-top: 2em;
}

.thg-payment-modal-confirm-btn{
	font-size: 1.4em;
	color: #ffffff;
	background-color: #E55604;
	padding: 0.5em 1em;
	border-radius: 0.2em;
	cursor: pointer;
}

</style>