<template>
	<div class="tgh-account-info-content">
		<div class="tgh-edit-address-field">
			<div class="tgh-edit-address-title">{{$t('收件人姓名')}} <span style="color: #E55604;">*</span></div>
			<a-input v-model:value="receiverName" size="large" :placeholder="$t('请输入收件人姓名')">

			</a-input>
<!--			<div class="tgh-account-info-value"><img src="../images/gold_coin.svg" alt="gold coin" style="width: 1em;margin-right: 0.4em;display: none;">฿{{ getDeposit }}</div>-->
		</div>
		<div class="tgh-edit-address-field">
			<div class="tgh-edit-address-title">{{$t('称谓')}}<span style="color: #E55604;">*</span></div>
			<div class="thg-edit-address-title-container">
				<div class="thg-edit-address-title-wrapper" v-for="item in titleGroup" :key="item.th" @click="onTitleClicked(item)">
					<img :src="item.isSelected ? require('../images/radio_selected.svg') : require('../images/radio_unselected.svg')" alt="radio unselected"
					     style="width: 1.4em; margin-right: 0.2em;">
					<div class="thg-payment-amount-text">{{ getTitleText(item) }}</div>
				</div>
			</div>
		</div>
		<div class="tgh-edit-address-field">
			<div class="tgh-edit-address-title"> {{$t('联系电话')}}<span style="color: #E55604;">*</span></div>
			<a-input v-model:value="contactCellPhone" size="large" :placeholder="$t('请输入收件人联系电话')">
			</a-input>
<!--			<div class="tgh-account-info-value"><img src="../images/gold_coin.svg" alt="gold coin" style="width: 1em;margin-right: 0.4em;display: none;">฿{{ getDeposit }}</div>-->
		</div>
		<div class="tgh-edit-address-field">
			<div class="tgh-edit-address-title"> {{$t('房屋号码和/或村庄名')}}<span style="color: #E55604;">*</span></div>
			<a-input v-model:value="houseNumber" size="large" :placeholder="$t('请输入房屋号码和/或村庄名')">
			</a-input>
<!--			<div class="tgh-account-info-value"><img src="../images/phone_blue.svg" alt="point" style="width: 1em;margin-right: 0.4em;max-height: 1em;">{{getPhoneNum}}</div>-->
		</div>
		<div class="tgh-edit-address-field">
			<div class="tgh-edit-address-title">{{$t('街道/巷弄名 （选填）')}}</div>
			<a-input v-model:value="streetNumber" size="large" :placeholder="$t('请输入街道/巷弄名')">
			</a-input>
<!--			<div class="tgh-account-info-value"><img src="../images/email.svg" alt="point" style="width: 1em;margin-right: 0.4em;max-height: 1em;">{{getUserEmail}}</div>-->
		</div>
		<div class="tgh-edit-address-field">
			<div class="tgh-edit-address-title">{{$t('乡/镇/区')}}<span style="color: #E55604;">*</span></div>
			<a-input v-model:value="district" size="large" :placeholder="$t('请输入乡/镇/区')">
			</a-input>
<!--			<div class="tgh-account-info-value"><img src="../images/email.svg" alt="point" style="width: 1em;margin-right: 0.4em;max-height: 1em;">{{getUserEmail}}</div>-->
		</div>
		<div class="tgh-edit-address-field">
			<div class="tgh-edit-address-title">{{$t('省份')}}<span style="color: #E55604;">*</span></div>
			<a-input v-model:value="province" size="large" :placeholder="$t('请输入省份')">
			</a-input>
<!--			<div class="tgh-account-info-value"><img src="../images/email.svg" alt="point" style="width: 1em;margin-right: 0.4em;max-height: 1em;">{{getUserEmail}}</div>-->
		</div>
		<div class="tgh-edit-address-field">
			<div class="tgh-edit-address-title">{{$t('邮编')}}<span style="color: #E55604;">*</span></div>
			<a-input v-model:value="postalCode" size="large" :placeholder="$t('请输入邮编')">
			</a-input>
<!--			<div class="tgh-account-info-value"><img src="../images/email.svg" alt="point" style="width: 1em;margin-right: 0.4em;max-height: 1em;">{{getUserEmail}}</div>-->
		</div>

		<div class="tgh-edit-address-btn-container">
			<div class="tgh-edit-address-btn" @click="onSaveClicked">{{$t('保存')}}</div>
		</div>
	</div>
</template>

<script>
import {Input, InputPassword, message, Space, Switch, Tabs} from 'ant-design-vue';
import {updateUserAddress} from "@/utils/methods";
export default {
	name: "AddressEditContent",
	components: {
		'a-input': Input,
	},
	data() {
		return {
			receiverName: '',
			receiverTitle: '',
			contactCellPhone: '',
			houseNumber: '',
			streetNumber: '',
			district: '',
			province: '',
			postalCode: '',
			titleGroup: [
				{
					th: 'คุณ',
					en: 'Mr.',
					zh: '先生/女士',
					isSelected: true
				},
				{
					th: 'นาย',
					en: 'Mr.',
					zh: '先生',
					isSelected: false
				},
				{
					th: 'นาง',
					en: 'Mrs.',
					zh: '女士',
					isSelected: false
				},
				{
					th: 'นางสาว',
					en: 'Miss',
					zh: '小姐',
					isSelected: false
				},
				{
					th: 'เด็กชาย',
					en: 'Master',
					zh: '少爷',
					isSelected: false
				},
				{
					th: 'เด็กหญิง',
					en: 'Miss',
					zh: '小姐',
					isSelected: false
				},
				{
					th: 'อาจารย์',
					en: 'Ajarn',
					zh: '教授',
					isSelected: false
				},
				{
					th: 'หมอ',
					en: 'Doctor',
					zh: '医生',
					isSelected: false
				},
				{
					th: 'ทนายความ',
					en: 'lawyer',
					zh: '律师',
					isSelected: false
				}
			]
		}
	},
	mounted() {
		const address = this.$store.state.userInfo.address
		if (address?.receiverName) {
			this.receiverName = address.receiverName
			// this.receiverTitle = address.receiverTitle
			this.titleGroup.forEach((value, index) => {
				if (value.th === address.receiverTitle) {
					value.isSelected = true
				} else {
					value.isSelected = false
				}
			})
			this.contactCellPhone = address.contactCellPhone
			this.houseNumber = address.houseNumber
			this.streetNumber = address.streetNumber
			this.district = address.district
			this.province = address.province
			this.postalCode = address.postalCode
		}

	},
	methods: {
		getTitleText(item) {
			// let languages = navigator.languages;
			// let toSet = languages[0]
			// if (toSet.toLowerCase().indexOf('zh') > -1) {
			// 	return item.zh
			// } else {
				return item.th
			// }
		},
		onTitleClicked (item) {
			this.titleGroup.forEach((value, index) => {
				if (value.th === item.th) {
					value.isSelected = true
				} else {
					value.isSelected = false
				}
			})
		},
		async onSaveClicked() {

			if (this.receiverName === '') {
				message.warning(this.$t('收件人姓名不能为空'))
				return
			}
			if (this.contactCellPhone === '') {
				message.warning(this.$t('收件人联系电话不能为空'))
				return
			}
			if (this.houseNumber === '') {
				message.warning(this.$t('房屋号码和/或村庄名不能为空'))
				return
			}
			if (this.district === '') {
				message.warning(this.$t('乡/镇/区不能为空'))
				return
			}
			if (this.province === '') {
				message.warning()
				return
			}
			if (this.postalCode === '') {
				message.warning(this.$t('邮编不能为空'))
				return
			}
			let title = ''
			this.titleGroup.forEach((value, index) => {
				if (value.isSelected) {
					title = value.th
				}
			})
			let data = {
				receiverName: this.receiverName,
				receiverTitle: title,
				contactCellPhone: this.contactCellPhone,
				houseNumber: this.houseNumber,
				streetNumber: this.streetNumber,
				district: this.district,
				province: this.province,
				postalCode: this.postalCode,
			}
			this.$store.commit('showLoading', {
				isLoading: true,
				loadingText:  this.$t('加载中...')
			})
			const result = await updateUserAddress(data)
			this.$store.commit('hideLoading')
			if (result.code !== 200) {
				message.error(this.$t('请稍候重试'))
				return
			}
			message.success(this.$t('保存成功'))
			this.$store.commit('updateUserAddressInfo', result.msg);
			this.$store.commit('showEditAddressModal', false)
		}
	}
}
</script>

<style scoped>
.tgh-account-info-content{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

.tgh-edit-address-field{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: flex-start;
	/*background-color: rgba(38, 87, 124, 0.2);*/
	/*padding: 1em;*/
	margin-bottom: 1em;
	box-sizing: border-box;
}

.tgh-edit-address-title{
	text-align: start;
	margin-bottom: 0.4em;
	font-size: 1.2em;
	display: flex;
	width: 100%;
	color: #26577C;
}

.tgh-account-info-value{
	text-align: start;
	margin-bottom: 0.2em;
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 1.4em;
}

.thg-edit-address-title-container{
	display: grid;
	grid-template-columns: repeat(3, 30%) !important;
	grid-gap: 0.8em;
	width: 100%;
}

.tgh-edit-address-btn-container{
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	padding: 1em 0;
}

.tgh-edit-address-btn{
	background-color: #26577C;
	color: #ffffff;
	font-size: 1.4em;
	padding: 0.4em 0.8em;
	border-radius: 0.2em;
}

</style>