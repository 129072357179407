<template>
	<div class="thg-history-list-container">
		<a-collapse style="width: 100%;">
			<template #expandIcon="{ isActive }">
				<caret-right-outlined :rotate="isActive ? 90 : 0" :style="{color: '#26577C'}"/>
			</template>
			<a-collapse-panel v-for="(item, index) in ticketList" :key="index"
			                  style="border: 1px solid #26577C; background-color: #ebebeb;margin-bottom: 0.6em; border-radius: 0;">

				<div v-for="item1 in item.tickets" :key="item1.ticketId" class="thg-history-list-tickets-container" :class="{'thg-ticket-is-win': item1.isWin === true}">
					<div>{{ item1.ticketId }} <span v-if="item1.isWin === true">{{$t('中奖')}}</span></div>
					<div>{{ item1.createTimeString }}</div>
				</div>
				<template #header>
					<div class="thg-history-list-header">{{ item.productTitleTH }}</div>
				</template>
				<template #extra>
					<div class="thg-history-list-extra-container">
						<div class="thg-history-list-ticket-state" :class="{'thg-ticket-is-drawn': item.isDrawn === true}">{{item.isDrawn === true ? $t('已开奖'):$t('未开奖')}}</div>
						<div style="margin-right: 0.4em;">{{$t('第几轮', {roundNum: item.roundNum})}}</div>
						<div>{{ item.tickets.length }}</div>
						<img src="../images/ticket_blue.svg" alt="ticket" style="width: 1.2em;">
					</div>
				</template>
			</a-collapse-panel>
		</a-collapse>
	</div>
</template>

<script>
import {Collapse, CollapsePanel} from 'ant-design-vue';
import {CaretRightOutlined} from "@ant-design/icons-vue";
import {getUserTickets, groupTicketList} from '@/utils/methods'

export default {
	name: "PurchaseHistoryContent",
	components: {
		'a-collapse': Collapse,
		'a-collapse-panel': CollapsePanel,
		CaretRightOutlined
	},
	data() {
		return {
			ticketList: []
		}
	},
	async mounted() {
		try{
			this.ticketList = this.$store.state.userInfo.tickets
			this.$emit('showModalSpinner')
			const result = await getUserTickets();
			this.$emit('hideModalSpinner')
			if (!result || result?.code !== 200) {
				this.ticketList = []
				this.$store.commit('updateTickets', [])
				return;
			}
			if (Array.isArray(result?.msg?.tickets) && result?.msg?.tickets?.length > 0) {
				const productGroup = groupTicketList(result.msg.tickets)
				this.ticketList = productGroup
				this.$store.commit('updateTickets', productGroup)
			}
		} catch (e) {

		}

	}
}
</script>

<style scoped>
.thg-history-list-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

.thg-history-list-header {
	color: #26577C;
	font-size: 1.2em;
	text-align: start;
}

.thg-history-list-extra-container {
	color: #26577C;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-size: 1.2em;
}

.thg-history-list-tickets-container {
	background-color: #EBE4D1;
	width: 100%;
	color: #26577C;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	font-size: 1.2em;
	padding: 0.6em;
	box-sizing: border-box;
	border-bottom: 1px solid rgba(38, 87, 124, 0.5);
}

.a-collapse-panel .ant-collapse-content .ant-collapse-content-box {
	padding: 0 !important;
}

.thg-history-list-ticket-state{
	color: #26577C;
	font-size: 1.2em;
	margin-right: 0.4em;
}

.thg-ticket-is-drawn{
	color: #E55604 !important;
}

.thg-ticket-is-win{
	color: #E55604 !important;
}


</style>