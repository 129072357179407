<template>
	<div class="thg-manual-content-container">
<!--		<div class="tgh-manual-item-container">{{ $t('1、充值获得金币；') }}</div>-->
<!--		<div class="tgh-manual-item-container">{{ $t('2、使用金币购买商城中的奖券；') }}</div>-->
<!--		<div class="tgh-manual-item-container">{{ $t('3、等待商品的奖券售罄后开奖；') }}</div>-->
<!--		<div class="tgh-manual-item-container">{{ $t('4、中奖后填写商品信息，颜色，款式等；') }}</div>-->
<!--		<div class="tgh-manual-item-container">{{ $t('5、在家等待奖品到达。') }}</div>-->
		<div class="tgh-manual-item-container">{{ $t('1、10泰铢可购买一个奖券；') }}</div>
		<div class="tgh-manual-item-container">{{ $t('2、同一款产品可以一次购买多个奖劵；') }}</div>
		<div class="tgh-manual-item-container">{{ $t('3、等待商品的奖券售馨后来奖；') }}</div>
		<div class="tgh-manual-item-container">{{ $t('4、中奖后配合客服确认获奖信息及收货地址；') }}</div>

	</div>
</template>

<script>
export default {
	name: "ManualContent"
}
</script>

<style scoped>
.thg-manual-content-container{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

.tgh-manual-item-container{
	width: 100%;
	text-align: start;
	color: #4F200D;
	margin-bottom: 0.2em;
	font-size: 1.4em;
}

</style>