const server = 'https://be.10tb10.com:7777'
import io from 'socket.io-client';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const {v4} = require("uuid");
const wsServer = 'https://be.10tb10.com:7777'

let fingerprint = null;
(async () => {
	const fp = await FingerprintJS.load({screen_resolution: true});

	const result = await fp.get();

	fingerprint = result.visitorId;

})();

function verifyEmail(email) {
	// const reg = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/;
	const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (email.value === "") {
		return false;
	}
	return reg.test(email);
}

async function getRequestAsync(url) {
	const myHeaders = new Headers();
	// myHeaders.append("User-Agent", "Apifox/1.0.0 (https://www.apifox.cn)");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Accept", "*/*");
	// myHeaders.append("Host", "124.220.215.2");
	myHeaders.append("Connection", "keep-alive");
	const sessionId = localStorage.getItem('sessionId')
	if (sessionId) {
		myHeaders.append("sessionId", sessionId);
	}

	// Add cache-control headers to prevent caching
	myHeaders.append("Cache-Control", "no-cache");
	myHeaders.append("Pragma", "no-cache");
	myHeaders.append("Expires", "0");

	const requestOptions = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow'
	};

	// const self = this

	const response = await fetch(`${server}/${url}`, requestOptions)
	return response.json()
}

async function postRequestAsync(url, data) {
	var myHeaders = new Headers();
	// myHeaders.append("User-Agent", "Apifox/1.0.0 (https://www.apifox.cn)");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Accept", "*/*");
	// myHeaders.append("Host", "49.51.186.159");
	myHeaders.append("Connection", "keep-alive");
	const sessionId = localStorage.getItem('sessionId')
	if (sessionId) {
		myHeaders.append("sessionId", sessionId);
	}

	// Add cache-control headers to prevent caching
	myHeaders.append("Cache-Control", "no-cache");
	myHeaders.append("Pragma", "no-cache");
	myHeaders.append("Expires", "0");

	const raw = JSON.stringify(data);

	const requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow'
	};

	// const self = this

	const response = await fetch(`${server}/${url}`, requestOptions)
	return response.json()
}

function removeLocalUserInfo() {
	localStorage.removeItem('sessionId')
	localStorage.removeItem('sessionExpireTimeStamp')
}

function saveSessionInfo(sessionId, sessionExpireTimeStamp) {
	localStorage.setItem('sessionId', sessionId)
	localStorage.setItem('sessionExpireTimeStamp', sessionExpireTimeStamp)
}

function getClientId() {
	const clientId = localStorage.getItem('clientId')
	if (clientId) {
		return clientId
	} else {
		const clientId = v4()
		localStorage.setItem('clientId', clientId)
		return clientId
	}
}

async function getUserInfoBySessionId() {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return null
	}
	return await getRequestAsync('account/getUserInfoBySessionId')
}

async function getUserTickets() {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return null
	}
	return await getRequestAsync('ticket/getTicket')
}

function gimmick(el, open) {
	var exists = document.getElementById('gimmick')

	if (open !== true) {
		if (exists) {
			exists.parentNode.removeChild(exists);
			return false;
		}
	}

	if (exists) {
		return
	}


	var element = document.querySelector(el);
	var canvas = document.createElement('canvas'),
		ctx = canvas.getContext('2d'),
		focused = false;

	canvas.width = window.innerWidth;
	canvas.height = window.innerHeight;
	canvas.id = 'gimmick'

	var coin = new Image();
	coin.src = 'http://i.imgur.com/5ZW2MT3.png'
	// 440 wide, 40 high, 10 states
	coin.onload = function () {
		element.appendChild(canvas)
		focused = true;
		drawloop();
	}
	var coins = []

	function drawloop() {
		if (focused) {
			requestAnimationFrame(drawloop);
		}

		ctx.clearRect(0, 0, canvas.width, canvas.height)

		if (Math.random() < .3) {
			coins.push({
				x: Math.random() * canvas.width | 0,
				y: -50,
				dy: 3,
				s: 0.5 + Math.random(),
				state: Math.random() * 10 | 0
			})
		}
		var i = coins.length
		while (i--) {
			var x = coins[i].x
			var y = coins[i].y
			var s = coins[i].s
			var state = coins[i].state
			coins[i].state = (state > 9) ? 0 : state + 0.1
			coins[i].dy += 0.3
			coins[i].y += coins[i].dy

			ctx.drawImage(coin, 44 * Math.floor(state), 0, 44, 40, x, y, 44 * s, 40 * s)

			if (y > canvas.height) {
				coins.splice(i, 1);
			}
		}
	}

}

function groupTicketList(ticketList) {
	const productGroup = []
	for (const item of ticketList) {
		const product = productGroup.find(x => x.sellId === item.sellId)
		if (!product) {
			productGroup.push({
				sellId: item.sellId,
				productId: item.productId,
				productTitle: item.productTitle,
				productTitleTH: item.productTitleTH,
				roundNum: item.roundNum,
				isDrawn: item.isDrawn,
				tickets: [item]
			})
		} else {
			product.tickets.push(item)
		}
	}
	return productGroup
}

async function getPromotionProducts() {

	return await getRequestAsync('products/getPromotionProducts')
}

function getMaskedEmail(email) {
	const atIndex = email.indexOf('@');
	if (atIndex === -1) {
		return email; // 如果没有找到 "@"，返回原始字符串
	}

	// 获取本地部分和域部分
	const localPart = email.substring(0, atIndex);
	const domainPart = email.substring(atIndex);

	if (localPart.length <= 4) {
		return email; // 如果本地部分不超过4个字符，返回原始邮箱地址
	}

	// 从本地部分保留最后四个字符，其余用 "x" 替代
	const maskedLocalPart = '*'.repeat(localPart.length - 4) + localPart.substring(localPart.length - 4);

	// 返回处理后的电子邮箱地址
	return maskedLocalPart + domainPart;
}


async function updateUserAddress(info) {

	return await postRequestAsync('account/updateUserAddress', info)
}




function randomFromTo(from, to) {
	return Math.floor(Math.random() * (to - from + 1) + from);
}

function confettiParticle(context) {
	const maxConfettis = 150;
	let W = window.innerWidth;
	let H = window.innerHeight;
	const possibleColors = [
		"DodgerBlue",
		"OliveDrab",
		"Gold",
		"Pink",
		"SlateBlue",
		"LightBlue",
		"Gold",
		"Violet",
		"PaleGreen",
		"SteelBlue",
		"SandyBrown",
		"Chocolate",
		"Crimson"
	];
	this.x = Math.random() * W; // x
	this.y = Math.random() * H - H; // y
	this.r = randomFromTo(11, 33); // radius
	this.d = Math.random() * maxConfettis + 11;
	this.color =
		possibleColors[Math.floor(Math.random() * possibleColors.length)];
	this.tilt = Math.floor(Math.random() * 33) - 11;
	this.tiltAngleIncremental = Math.random() * 0.07 + 0.05;
	this.tiltAngle = 0;

	this.draw = function () {
		context.beginPath();
		context.lineWidth = this.r / 2;
		context.strokeStyle = this.color;
		context.moveTo(this.x + this.tilt + this.r / 3, this.y);
		context.lineTo(this.x + this.tilt, this.y + this.tilt + this.r / 5);
		return context.stroke();
	};
}

function socketConnect() {
	let scoketConnId
	scoketConnId = localStorage.getItem('sessionId')
	if (!scoketConnId) {
		scoketConnId = v4()
	}
	global.socketConnection = io(wsServer,
		{
			query: {
				id: scoketConnId // 这里将用户ID作为查询参数发送
			},
			// 自动重新连接
			reconnection: true,
			// 初始重连的延迟
			reconnectionDelay: 1000,
			// 最大重连延迟
			reconnectionDelayMax: 5000,
			// 尝试重新连接的次数
			reconnectionAttempts: Infinity
		});

	return global.socketConnection
}

const global = {
	socketConnection: null
}

async function getWinnerList() {
	return await getRequestAsync('winner/getWinnerList')
}

function isValidThaiPhoneNumber(phoneNumber) {
	// 泰国电话号码通常为 9 位数字，不包括国家代码和区号
	// 此正则表达式验证它是否恰好是 9 位数字
	// const regex = /^\d{10}$/;
	// return regex.test(phoneNumber);

	const pattern = /^\d{9,10}$/;
	return pattern.test(phoneNumber);
}

function maskPhoneNumber(phoneNumber) {
	// 确保输入是一个字符串
	phoneNumber = String(phoneNumber);

	// 检查手机号码是否足够长
	if (phoneNumber.length < 4) {
		return '';
	}

	// 保留最后四位，其余位数替换为星号
	return phoneNumber.slice(0, -4).replace(/./g, '*') + phoneNumber.slice(-4);
}

async function getGeneralProducts () {
	return await getRequestAsync('products/getGeneralProducts')
}

async function getPaymentUrl (data) {
	return await postRequestAsync('payment/createPayment', data)
}


export {
	verifyEmail,
	getRequestAsync,
	postRequestAsync,
	removeLocalUserInfo,
	saveSessionInfo,
	getClientId,
	fingerprint,
	getUserInfoBySessionId,
	getUserTickets,
	gimmick,
	groupTicketList,
	getPromotionProducts,
	confettiParticle,
	socketConnect,
	global,
	getMaskedEmail,
	updateUserAddress,
	getWinnerList,
	isValidThaiPhoneNumber,
	maskPhoneNumber,
	getGeneralProducts,
	getPaymentUrl
};