<template>
	<div class="thg-phone-menu-container" @click="hideMenu">
		<div class="thg-phone-menu-content">
			<div class="thg-phone-menu-wrapper">
				<div class="thg-phone-menu-item" @click="onShowMyTicket">
					<img src="../images/ticket.svg" alt="cart" class="thg-phone-menu-img"/>
					<div>{{ $t('奖券') }}</div>
				</div>
				<div class="thg-phone-menu-item" @click="showManual">
					<img src="../images/help.svg" alt="help" class="thg-phone-menu-img"/>
					<div>{{ $t('规则') }}</div>
				</div>
				<div class="thg-phone-menu-item" @click="showAccountInfo">
					<img src="../images/user.svg" alt="user" class="thg-phone-menu-img"/>
					<div>{{ $t('我') }}</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
export default {
	name: "PhoneMenu",
	methods: {
		hideMenu() {
			this.$emit('hideMenu')
		},
		showAccountInfo() {
			this.$emit('showAccountInfo')
			this.$emit('hideMenu')
		},
		showManual() {
			this.$emit('showManual')
			this.$emit('hideMenu')
		},
		onShowMyTicket() {
			this.$emit('showMyTicket')
			this.$emit('hideMenu')
		}
	}
}
</script>

<style scoped>
.thg-phone-menu-container {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.4);
	top: 0;
	left: 0;
	z-index: 110;
}

.thg-phone-menu-content {
	background-color: transparent;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	position: relative;
}

.thg-phone-menu-wrapper {
	width: fit-content;
	background-color: #EBE4D1;
	position: absolute;
	padding: 0 1em;
	box-sizing: border-box;
	right: 0;
	height: 100%;
	min-width: 14rem;
}

.thg-phone-menu-item {
	padding: 1em 0;
	box-sizing: border-box;
	border-bottom: 1px solid #4F200D;
	width: 100%;
	text-align: center;
	color: #4F200D;
	font-size: 1.4rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: start;
	padding-left: 1em;
}

.thg-phone-menu-img {
	width: 1.6rem;
	margin-right: 0.5em;
}

</style>