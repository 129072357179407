<template>
	<div class="thg-promotion-product-container">
		<div class="thg-promotion-mask"
		     v-if="productItem.isOpen === false && productItem.isDrawing !== true && productItem.isFinished !== true">
			{{$t('未开始')}}
		</div>
		<div class="thg-promotion-mask" v-if="productItem.isDrawing === true">
			<div class="thg-promotion-mask-drawing-content">

				{{$t('正在开奖')}}
				<div class="thg-promotion-mask-drawing-image-container">
					<img src="../images/wheel_pointer.svg" alt="wheel pointer" class="thg-drawing-wheel-pointer">
					<img src="../images/wheel.svg" alt="wheel" class="thg-drawing-wheel-rotate">
				</div>
			</div>
		</div>
		<div class="thg-promotion-mask" v-if="productItem.isFinished === true">
			<div class="thg-promotion-mask-drawing-content">

				<div>{{$t('已开奖')}}</div>
				<div style="font-size: 0.8em;color: #ffffff;margin-top: 0.4em;">{{$t('中奖号码')}}</div>
				<div style="font-size: 0.8em;color: #ffffff;">{{ productItem?.winnerTicketId }}</div>
			</div>
		</div>
		<div class="thg-promotion-product-title">{{ productItem.productTitle }}<br>{{$t('第几轮', {roundNum: productItem.roundNum})}}</div>
		<div class="thg-promotion-product-progress-container">
			<div class="thg-sell-progress-text"><img src="../images/ticket_red.svg" alt="ticket"
			                                         class="thg-promotion-product-ticket">{{
					productItem.currentTicketNum
				}}/{{ productItem.totalTicket }}
			</div>
			<div class="thg-promotion-product-progress-bar-bg">
				<div class="thg-promotion-product-progress-bar"
				     :class="{'progress-bar-blinking': productItem.isOpen === true && productItem.isFinished === false}"
				     :style="sellPercentage"/>
			</div>
		</div>
		<div class="thg-promotion-product-img-container">
			<img class="thg-promotion-product-img"
			     src="../images/products/redmi10a.png"
			     alt="promotion product">
		</div>
	</div>
</template>

<script>
export default {
	name: "PromotionProductItem",
	props: ['productItem'],
	computed: {
		sellPercentage() {
			return {

				width: this.productItem.currentTicketNum / this.productItem.totalTicket * 100 + '%'

			}
		}
	}
}
</script>

<style scoped>
.thg-promotion-product-img-container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 0 2em;
	box-sizing: border-box;
}

.thg-promotion-mask {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	color: #fff;
	font-size: 1.6em;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
	white-space: nowrap;
}

.thg-promotion-product-container {
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: center;
	padding: 1em;
	box-sizing: border-box;
	background-color: rgba(229, 86, 4, 0.2);
	position: relative;
}

.thg-promotion-product-img {
	width: 90%;
}

.thg-promotion-product-title {
	color: #E55604;
	font-size: 1.6em;
	margin-bottom: 0.4em;
}

.thg-promotion-product-progress-container {
	width: 100%;
	padding: 1em 0;
}

.thg-promotion-product-progress-bar-bg {
	width: 100%;
	height: 12px;
	background-color: #014155;
	border-radius: 2px;
	position: relative;
}

.thg-promotion-product-ticket {
	width: 1.2em;
	margin-right: 0.4em;
}

.thg-promotion-product-progress-bar {
	position: absolute;
	background-color: #E55604;
	height: 12px;
	top: 0;
	left: 0;
	width: 30%;
}

.thg-promotion-mask-drawing-content {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	white-space: nowrap;
}

.thg-promotion-mask-drawing-image-container {
	width: 100%;
	height: 4em;
	position: relative;
}

@keyframes blink-animation {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.thg-drawing-wheel-pointer {
	width: 2em;
	position: absolute;
	top: 15%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 10;
}


.thg-drawing-wheel-rotate {
	width: 3em;
	animation: rotate 4s infinite linear;
	position: absolute;
	top: 50%;
	left: 50%;
}


@keyframes rotate {
	from {
		transform: translate(-50%, -50%) rotate(0deg);
	}
	to {
		transform: translate(-50%, -50%) rotate(360deg);
	}
}

.progress-bar-blinking {
	animation: blink-animation 1s infinite;
	box-shadow: 0 0 8px #ffe6d7;
}

.thg-sell-progress-text {
	color: #E55604;
	font-size: 1.4em;
	margin-bottom: 0.4em;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}


</style>