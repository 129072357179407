<template>
	<div class="thg-modal-bg">

		<div class="thg-confirm-modal-container">
			<div class="thg-confirm-modal-title-container">
				<div>{{ getTitle }}</div>
				<img src="../images/close.svg" alt="close" class="thg-modal-close" @click="closeConfirmModal">
			</div>
			<div class="thg-confirm-modal-content">
				{{getContent}}
			</div>
			<div class="thg-confirm-modal-btn-container">
				<div class="thg-primary-btn" @click="closeConfirmModal">
					{{ $t('确定') }}
				</div>

			</div>
		</div>
	</div>
</template>

<script>
import {gimmick} from "@/utils/methods";

export default {
	name: "ConfirmModal",
	props: [],
	data() {
		return {
			isModalSpin: false
		}
	},
	components: {
	},
	computed: {
		getTitle() {
			return this.$store.state.confirmModalInfo.title;
		},
		getContent() {
			return this.$store.state.confirmModalInfo.content;
		},
		showCancel() {
			return this.$store.state.confirmModalInfo.showCancel;

		},
		showGoldDrop() {
			return this.$store.state.confirmModalInfo.showGoldDrop;
		}
	},
	mounted() {
		if (this.showGoldDrop === true) {
			gimmick('body', true);
		}
	},
	methods: {
		closeConfirmModal() {
			gimmick('body', false);
			if (typeof this.$store.state.confirmModalInfo.afterClose === 'function') {
				this.$store.state.confirmModalInfo.afterClose();
			}
			this.$store.commit('hideConfirmModal');

		},
		showModalSpinner() {
			this.isModalSpin = true;
		},
		hideModalSpinner() {
			this.isModalSpin = false;
		}
	}

}
</script>

<style scoped>
.thg-confirm-modal-btn-container{
	width: 100%;
	text-align: center;
	margin: 1em 0;
	display: flex;
	align-items: center;
	justify-content: center;
}
.thg-confirm-modal-content{
	padding: 1em;
	background-color: #EBE4D1;
	color: #E55604;
	max-height: 75vh;
	overflow-y: scroll;
	text-align: start;
	font-size: 1.4em;
}
.thg-modal-bg {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
	z-index: 110;
}

.thg-confirm-modal-container {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 60%;
	background-color: #EBE4D1;
	z-index: 101;
}

.thg-confirm-modal-title-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	color: #E55604;
	padding: 1em;
	box-sizing: border-box;
	font-size: 1.2em;
	border-bottom: 2px solid rgba(229, 86, 4, 0.2);
}

.thg-modal-icon {
	width: 1.2em;
	color: #E55604;
	margin-right: 0.4em;
}

.thg-modal-close {
	width: 1em;
	margin-left: auto;
	opacity: 0.7;
	cursor: pointer;
}
</style>