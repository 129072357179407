<template>
	<a-spin :tip="getLoadingInfo.loadingText" size="large" :spinning="getLoadingInfo.isLoading">
		<MainPage/>
	</a-spin>
</template>

<script>
import MainPage from './components/MainPage.vue'
import {Spin} from 'ant-design-vue'

export default {
	name: 'App',
	components: {
		MainPage,
		'a-spin': Spin
	},
	computed: {
		getLoadingInfo() {
			return this.$store.state.loadingInfo;
		}
	},
	created() {
		// 监听事件数据的变化
		this.$store.watch(
			(state) => state.isLoading,
			(newData) => {
				// 处理事件数据的变化
			}
		);
	},
	methods: {}
}
</script>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	margin-top: 60px;
}

/* 滚动条轨道 */
::-webkit-scrollbar {
	width: 8px; /* 滚动条宽度 */
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
	background-color: #888; /* 滑块背景颜色 */
	border-radius: 4px; /* 滑块圆角 */
}

/* 滚动条轨道上的按钮 */
::-webkit-scrollbar-button {
	display: none; /* 隐藏按钮 */
}

/* 滚动条轨道两端空白区域 */
::-webkit-scrollbar-track {
	background-color: #EBE4D1; /* 轨道背景颜色 */
}

/* 鼠标悬停在滚动条上时的样式 */
::-webkit-scrollbar-thumb:hover {
	background-color: #555; /* 悬停时的滑块背景颜色 */
}

/* 滚动条在被激活（点击）时的样式 */
::-webkit-scrollbar-thumb:active {
	background-color: #333; /* 激活时的滑块背景颜色 */
}

.a-collapse-panel .ant-collapse-content .ant-collapse-content-box {
	padding: 0 !important;
}


.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #E55604 !important;
}

.ant-tabs .ant-tabs-ink-bar {
	background: #E55604 !important;
}

.ant-tabs .ant-tabs-tab:hover {
	color: #e07f46 !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
	border-color: #E55604;
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
	border-color: #E55604;
}

.ant-switch.ant-switch-checked {
	background: #E55604;
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
	background: #E55604;
}

.ant-switch:focus-visible {
	outline: 2px solid #e07f46;
}

.thg-primary-btn {
	background-color: #E55604;
	border-radius: 4px;
	padding: 0.6em 1.6em;
	font-size: 1.2em;
	color: #EBE4D1;
	cursor: pointer;
	user-select: none;
	width: fit-content;
}

.thg-primary-btn:active {
	background-color: #b04402;
}

.ant-spin .ant-spin-dot-item {
	background-color: #E55604;
}

.ant-spin {
	color: #E55604;
}

.ant-spin-nested-loading > div > .ant-spin {
	font-size: 1.4em;
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {

	padding-top: 0.8em;
	text-shadow: 0 1px 1px #d7835c;
}
.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
	padding: 0 !important;
}

.thg-popconfirm-confirm-btn{
	background-color: #E55604;
	border-radius: 4px;
	padding: 0.2em 0.4em;
	font-size: 1em;
	color: #EBE4D1;
	cursor: pointer;
	user-select: none;
	width: fit-content;
	margin: auto;
}

.thg-popconfirm-title{
	color:  #E55604;
	font-size: 1.2em;
}
.ant-divider .ant-divider-inner-text {
	font-size: 2em;
}

@media (min-width: 1400px) and (max-width: 1800px) {

}
@media (min-width: 900px) and (max-width: 1400px) {

}
@media (max-width: 900px) {
	.ant-divider .ant-divider-inner-text {
		font-size: 1em;
	}

	.thg-promotion-product-title{
		font-size: 1.1em !important;
		margin-bottom: 0 !important;
		text-align: start !important;
	}
	.thg-sell-progress-text{
		font-size: 1.1em !important;
	}
	.thg-promotion-product-container{
		padding: 0.6em !important;
	}
	.thg-promotion-product-img-container{
		padding: 0 !important;
	}
	.thg-promotion-product-progress-container{
		padding: 0.4em 0 !important;
	}
	.thg-promotion-mask{
		font-size: 1.2em !important;
	}
	.thg-carousel-buy-btn {
		width: 9em !important;
	}
	.thg-carousel-buy-btn{
		font-size: 1.2em !important;
	}
	.thg-carousel-buy-btn{
		right: 0 !important;
	}
	.thg-carousel-buy-text-container{
		right: 3em !important;
	}
	.thg-main-section-title{
		font-size: 1.6em !important;
	}
	.thg-modal-container{
		width: 90% !important;
	}
	.thg-modal-content-container{
		padding: 0.6em !important;
	}
	.thg-history-list-tickets-container{
		padding: 0.3em !important;
		font-size: 1em !important;
	}
	.ant-collapse>.ant-collapse-item >.ant-collapse-header{
		padding: 4px 8px !important;
	}

	.thg-web-title{
		font-size: 1.4em !important;
	}
	.thg-menu-container{
		display: none !important;
	}

	.thg-congrats-section{
		padding: 1em !important;
	}
}
</style>
