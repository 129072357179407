<template>
	<div class="tgh-account-info-content">
		<div class="tgh-account-info-field">
			<div class="tgh-account-info-title">{{ $t('账户余额') }}</div>
			<div class="tgh-account-info-value"><img src="../images/gold_coin.svg" alt="gold coin" style="width: 1em;margin-right: 0.4em;display: none;">฿{{ getDeposit }}</div>
		</div>
		<div class="tgh-account-info-field">
			<div class="tgh-account-info-title">{{ $t('手机号') }}</div>
			<div class="tgh-account-info-value"><img src="../images/phone_blue.svg" alt="point" style="width: 1em;margin-right: 0.4em;max-height: 1em;">{{getPhoneNum}}</div>
		</div>
		<div class="tgh-account-info-field">
			<div class="tgh-account-info-title">{{ $t('邮箱') }}</div>
			<div class="tgh-account-info-value"><img src="../images/email.svg" alt="point" style="width: 1em;margin-right: 0.4em;max-height: 1em;">{{getUserEmail}}</div>
		</div>
		<div class="tgh-account-info-field">
			<div class="tgh-account-info-title">{{ $t('收货地址') }} <img src="../images/edit.svg" alt="edit" style="width: 1em;cursor: pointer;" @click="onEditAddress"></div>
			<div class="tgh-account-info-value"><img src="../images/address.svg" alt="point" style="width: 0.8em;margin-right: 0.4em;max-height: 1em;"><div v-html="getAddress"/></div>
		</div>
	</div>
</template>

<script>
import {getUserInfoBySessionId} from "@/utils/methods";

export default {
	name: "AccountInfoContent",
	computed: {
		getUserEmail() {
			return this.$store.state.userInfo.email
		},
		getDeposit() {
			return this.$store.state.userInfo.deposit
		},
		getAddress() {
			if (!this.$store.state.userInfo.address.receiverName) {
				return this.$t('未填写')
			}
			const address = this.$store.state.userInfo.address
			return `${address.receiverTitle} ${address.receiverName}<br/>${address.contactCellPhone}<br/>${address.houseNumber}<br/>${address.streetNumber}<br/>${address.district}<br/>${address.province}<br/>${address.postalCode}`
		},
		getPhoneNum() {
			return this.$store.state.userInfo.phoneNum || this.$t('未填写')
		}
	},
	async mounted() {
		const result = await getUserInfoBySessionId()
		this.$store.commit('hideLoading');
		if (!result || result?.code !== 200) {
			this.$store.commit('setUserLogin', false);
		} else {
			if (result?.code === 200 && result?.msg) {
				this.$store.commit('setIsLogin', result?.msg);
				if (!result?.msg?.address || !result?.msg?.address?.receiverName) {
					this.$store.commit('updateShowEditAddressInfo', {
						hideClose: true,
						modalTitle: this.$t('请输入您接收奖品的地址'),
						type: 'address',
						modalIcon: require('../images/address_red.svg')
					})
					this.$store.commit('showEditAddressModal', true)
				}
			}
		}
	},
	methods: {
		onEditAddress() {
			this.$store.commit('updateShowEditAddressInfo', {
				hideClose: false,
				modalTitle: this.$t('编辑收货地址'),
				type: 'address',
				modalIcon: require('../images/address_red.svg')
			})
			this.$store.commit('showEditAddressModal', true)
		}
	}
}
</script>

<style scoped>
.tgh-account-info-content{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

.tgh-account-info-field{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: flex-start;
	background-color: rgba(38, 87, 124, 0.2);
	padding: 1em;
	margin-bottom: 1em;
	box-sizing: border-box;
}

.tgh-account-info-title{
	text-align: start;
	font-weight: bold;
	margin-bottom: 0.4em;
	font-size: 1.4em;
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.tgh-account-info-value{
	text-align: start;
	margin-bottom: 0.2em;
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 1.4em;
}

</style>