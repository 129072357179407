<template>
	<a-tabs v-model:activeKey="activeKey" centered>
		<a-tab-pane key="1">
			<a-space direction="vertical" class="thg-login-container">
				<a-space direction="horizontal" :align="'start'" style="width: 100%;">
					<a-switch v-model:checked="loginTypeCheck" @change="onLoginTypeChange"/>
					<div style="color: #E55604;font-size: 1.2em;">{{ $t('使用邮箱登录') }}</div>
				</a-space>

				<a-input v-if="isEmailLogin === false" v-model:value="loginPhone" size="large" :placeholder="$t('请输入手机号')"
				         style="width: 100%;">
					<template #prefix>
						<img src="../images/phone.svg" alt="email" class="thg-login-input-icon">
						+66
					</template>
				</a-input>
				<a-input v-if="isEmailLogin === true" v-model:value="loginEmail" size="large"
				         :placeholder="$t('请输入邮箱地址')"

				         style="width: 100%;">
					<template #prefix>
						<img src="../images/email_red.svg" alt="email" class="thg-login-input-icon">
					</template>
				</a-input>
				<a-input-password v-model:value="loginPassword" size="large" autofocus :placeholder="$t('在此输入密码')"
				                  style="width: 100%;">
					<template #prefix>
						<img src="../images/password.svg" alt="password" class="thg-login-input-icon">
					</template>
				</a-input-password>
				<div style="width: 100%; text-align: center; margin-top: 1em;">
					<div class="thg-primary-btn" @click="onLogin" style="margin:auto;">
						{{ $t('登录') }}
					</div>

				</div>
			</a-space>
			<template #tab>
				<div class="thg-login-modal-tab-title" style="margin: auto;">
					{{ $t('会员登录') }}
				</div>
			</template>
		</a-tab-pane>
		<a-tab-pane key="2" force-render>

			<a-space direction="vertical" class="thg-reg-container">
				<a-space direction="horizontal" :align="'start'" style="width: 100%;">
					<a-switch v-model:checked="regTypeCheck" @change="onRegTypeChange"/>
					<div style="color: #E55604;font-size: 1.2em;">
						{{ $t('使用邮箱注册') }}
					</div>
				</a-space>

				<a-input v-if="isEmailReg === false" v-model:value="regPhone" size="large" :placeholder="$t('请输入手机号')"
				         style="width: 100%;">
					<template #prefix>
						<img src="../images/phone.svg" alt="email" class="thg-login-input-icon">
						+66
					</template>
				</a-input>
				<a-input v-if="isEmailReg === true" v-model:value="regEmail" size="large" :placeholder="$t('请输入邮箱地址')"
				         style="width: 100%;">
					<template #prefix>
						<img src="../images/email_red.svg" alt="email" class="thg-login-input-icon">
					</template>
				</a-input>

				<a-input v-model:value="regVcode" size="large" :placeholder="$t('请输入验证码')" style="width: 100%;">
					<template #prefix>
						<img src="../images/vcode.svg" alt="email" class="thg-login-input-icon">
					</template>
					<template #suffix>
						<div style="color: #E55604;cursor: pointer;" @click="onSendVcode">{{ getSendVcodeText }}</div>
					</template>
				</a-input>
				<a-input-password v-model:value="regPassword" size="large" autofocus :placeholder="$t('在此输入密码')"
				                  style="width: 100%;">
					<template #prefix>
						<img src="../images/password.svg" alt="password" class="thg-login-input-icon">
					</template>
				</a-input-password>
				<a-input-password v-model:value="regPasswordConfirm" size="large" autofocus :placeholder="$t('在此确认密码')"
				                  style="width: 100%;">
					<template #prefix>
						<img src="../images/password.svg" alt="password" class="thg-login-input-icon">
					</template>
				</a-input-password>
				<div style="width: 100%; text-align: center; margin-top: 1em;">
					<div class="thg-primary-btn" @click="onReg" style="margin:auto;">
						{{ $t('注册') }}
					</div>

				</div>
			</a-space>
			<template #tab>
				<div class="thg-login-modal-tab-title">
					{{ $t('注册') }}
				</div>
			</template>
		</a-tab-pane>
	</a-tabs>
</template>

<script>
import {Input, InputPassword, message, Space, Switch, Tabs} from 'ant-design-vue';
import {
	fingerprint,
	getClientId,
	groupTicketList,
	isValidThaiPhoneNumber,
	postRequestAsync,
	saveSessionInfo,
	verifyEmail
} from '@/utils/methods';

export default {
	name: "LoginContent",
	components: {
		'a-tabs': Tabs,
		'a-tab-pane': Tabs.TabPane,
		'a-input': Input,
		'a-space': Space,
		'a-input-password': InputPassword,
		'a-switch': Switch
	},
	computed: {
		getSendVcodeText() {
			if (this.sendVcodeCountdown < 0) {
				return this.$t('发送验证码');
			} else {
				return this.$t('n秒后重发', {n: this.sendVcodeCountdown});
			}
		},
		isEmailLogin() {
			return this.loginType === 'email'

		},
		isEmailReg() {
			return this.regType === 'email'
		}
	},
	data() {
		return {
			activeKey: '1',
			loginEmail: '',
			loginPhone: '',
			loginPassword: '',
			loginType: 'phone',
			loginTypeCheck: false,
			regType: 'phone',
			regTypeCheck: false,
			regEmail: '',
			regPhone: '',
			regPassword: '',
			regPasswordConfirm: '',
			regVcode: '',
			sendVcodeCountdown: -1,
			sendVcodeCountdownInterval: null
		}
	},
	methods: {
		onLoginTypeChange(checked) {
			if (checked) {
				this.loginType = 'email'
			} else {
				this.loginType = 'phone'
			}
		},
		onRegTypeChange(checked) {
			if (checked) {
				this.regType = 'email'
			} else {
				this.regType = 'phone'
			}
		},
		async onLogin() {

			try {
				if (this.loginType === 'email') {
					if (!this.loginEmail) {
						message.warning(this.$t('邮箱不能为空'));
						return
					}
					if (!verifyEmail(this.loginEmail)) {
						message.warning(this.$t('邮箱格式不正确'));
						return
					}
				}

				if (this.loginType === 'phone') {
					if (!this.loginPhone) {
						message.warning(this.$t('手机号不能为空'));
						return
					}
					if (!isValidThaiPhoneNumber(this.loginPhone)) {
						message.warning(this.$t('手机号格式不正确'));
						return
					}
				}

				if (!this.loginPassword) {
					message.warning(this.$t('密码不能为空'));
					return
				}
				let data
				if (this.loginType === 'email') {
					data = {
						email: this.loginEmail,
						password: this.loginPassword
					}
				} else {
					data = {
						phoneNum: this.loginPhone,
						password: this.loginPassword
					}
				}
				this.$store.commit('showLoading', {
					isLoading: true,
					loadingText: this.$t('加载中...')
				})
				const result = await postRequestAsync('account/verifyAccount', data)
				this.$store.commit('hideLoading');
				if (result?.code === 201) {
					message.error(this.$t('用户不存在'));
					return
				}
				if (result?.code === 202) {
					message.error(this.$t('账号与密码不匹配'));
					return
				}
				if (result?.code === 200) {
					message.success(this.$t('登录成功'));
					const sessionId = result.msg.sessionId;
					const sessionExpireTimeStamp = result.msg.sessionExpireTimeStamp;
					saveSessionInfo(sessionId, sessionExpireTimeStamp);
					this.$store.commit('setIsLogin', result.msg.userInfo);
					this.$store.commit('hideModal');
					return
				}
				message.error(this.$t('网络错误，请稍候重试'));
			} catch (e) {

			} finally {
				this.$store.commit('hideLoading');
			}

		},
		async onReg() {
			try {
				if (this.regType === 'email') {
					if (!this.regEmail) {
						message.warning(this.$t('邮箱不能为空'));
						return
					}

					if (!verifyEmail(this.regEmail)) {
						message.warning(this.$t('邮箱格式不正确'));
						return
					}
				}

				if (this.regType === 'phone') {
					if (!this.regPhone) {
						message.warning(this.$t('手机号不能为空'));
						return
					}

					if (!isValidThaiPhoneNumber(this.regPhone)) {
						message.warning(this.$t('手机号格式不正确'));
						return
					}
				}

				if (!this.regVcode) {
					message.warning(this.$t('验证码不能为空。'));
					return
				}

				if (!this.regPassword) {
					message.warning(this.$t('密码不能为空'));
					return
				}

				if (!this.regPasswordConfirm) {
					message.warning(this.$t('确认密码不能为空'));
					return
				}

				if (this.regPassword !== this.regPasswordConfirm) {
					message.warning(this.$t('两次密码不一致'));
					return
				}
				// const fingerPrintId = await getFingerprint();
				const clientId = getClientId()
				let data
				if (this.regType === 'phone') {
					data = {
						phoneNum: this.regPhone,
						vcode: this.regVcode,
						password: this.regPassword,
						fingerPrintId: fingerprint,
						clientId: clientId
					}

				} else {
					data = {
						email: this.regEmail,
						vcode: this.regVcode,
						password: this.regPassword,
						fingerPrintId: fingerprint,
						clientId: clientId
					}
				}
				this.$store.commit('showLoading', {
					isLoading: true,
					loadingText: this.$t('加载中...')
				})
				const result = await postRequestAsync('account/verifyRegister', data)
				this.$store.commit('hideLoading');
				if (result?.code === 201) {
					message.error(this.$t('验证码不正确'));
					return
				}
				if (result?.code === 202) {
					message.error(this.$t('验证码已过期'));
					return
				}
				if (result?.code === 200) {
					message.success(this.$t('注册成功'));
					const sessionId = result.msg.sessionId;
					const sessionExpireTimeStamp = result.msg.sessionExpireTimeStamp;
					const ticketList = result.msg.ticketList;
					saveSessionInfo(sessionId, sessionExpireTimeStamp);

					this.$store.commit('hideModal');
					if (Array.isArray(ticketList) && ticketList.length > 0) {
						this.$store.commit('showConfirmModal', {
							display: true,
							title: this.$t('新会员奖励'),
							content: this.$t('恭喜您获得了5张Remi 12C抽奖券！', {number: '5', product: 'Remi 12C'}),
							showCancel: false,
							showGoldDrop: true,
							afterClose: () => {
								this.$store.commit('showMyTicketsPopconfirm', true);
							}
						})
						const groupTickets = groupTicketList(ticketList)
						this.$store.commit('setIsLogin', {email: this.regEmail, tickets: groupTickets, phoneNum: this.phoneNum});

						this.$store.commit('updateShowEditAddressInfo', {
							hideClose: true,
							modalTitle: this.$t('请输入您接收奖品的地址'),
							type: 'address',
							modalIcon: require('../images/address_red.svg')
						})
						this.$store.commit('showEditAddressModal', true)
					} else {
						this.$store.commit('setIsLogin', {email: this.regEmail, phoneNum: this.phoneNum});
					}
					return
				}
				message.error(this.$t('网络错误，请稍候重试'));
			} catch (e) {
				console.log(e.message)
			} finally {
				this.$store.commit('hideLoading');
			}

		},
		async onSendVcode() {
			try {
				if (this.sendVcodeCountdown >= 0) {
					return
				}
				let result
				if (this.regType === 'email') {
					if (!this.regEmail) {
						message.warning(this.$t('邮箱不能为空'));
						return
					}
					if (!verifyEmail(this.regEmail)) {
						message.warning(this.$t('邮箱格式不正确'));
						return
					}
					this.$store.commit('showLoading', {
						isLoading: true,
						loadingText: this.$t('加载中...')
					})
					result = await postRequestAsync('account/sendRegisterEmail', {email: this.regEmail})
					this.$store.commit('hideLoading');
				}

				if (this.regType === 'phone') {

					if (!this.regPhone) {
						message.warning(this.$t('手机号不能为空'));
						return
					}
					if (!isValidThaiPhoneNumber(this.regPhone)) {
						message.warning(this.$t('手机号格式不正确'));
						return
					}
					this.$store.commit('showLoading', {
						isLoading: true,
						loadingText: this.$t('加载中...')
					})
					result = await postRequestAsync('account/sendRegisterPhone', {phoneNum: this.regPhone})
					this.$store.commit('hideLoading');
				}


				if (result?.code === 202) {
					message.warning(this.$t('邮箱已注册请直接登录'));
					return
				}
				if (result?.code === 201) {
					message.error(this.$t('邮件发送失败，请稍候重试'));
					return
				}
				if (result?.code === 203) {
					message.error(this.$t('手机号已注册请直接登录'));
					return
				}
				if (result?.code === 204) {
					message.error(this.$t('验证码发送失败，请稍候重试'));
					return
				}

				if (result?.code === 200) {
					message.success(this.$t('验证码已成功发出'));
					this.sendVcodeCountdown = 60;
					this.sendVcodeCountdownInterval = setInterval(() => {
						this.sendVcodeCountdown--;
						if (this.sendVcodeCountdown < 0) {
							clearInterval(this.sendVcodeCountdownInterval);
						}
					}, 1000);
					return
				}
				message.error(this.$t('网络错误，请稍候重试'));
			} catch (e) {

			} finally {
				this.$store.commit('hideLoading');
			}

		}
	}
}
</script>

<style scoped>
.thg-login-container, .thg-login-container {
	width: 30em;
}

@media (max-width: 900px) {

	.thg-login-container, .thg-login-container {
		width: 100% !important;
	}

	.thg-login-modal-tab-title {
		font-size: 1.2em !important;
	}
}

.thg-login-modal-tab-title {
	font-size: 1.5em;
}

.thg-login-input-icon {
	width: 1.5em;
	max-height: 1.5em;
	padding-right: 0.4em;
}

</style>