import {createI18n} from 'vue-i18n'
import {getMaskedEmail} from "@/utils/methods";

// 定义你的语言环境信息
const messages = {
	zh: {
		"泰会购": '泰会购',
		"账户余额": '账户余额',
		"邮箱": '邮箱',
		"新人大礼包": '新人大礼包',
		"在这里查看您的奖券": '新人大礼包',
		"第几轮": '第{roundNum}轮',
		"未开始": "未开始",
		"已开奖": "已开奖",
		"收货地址": "收货地址",
		"中奖号码": "中奖号码",
		"正在开奖": "正在开奖",
		"会员登录": "会员登录",
		"注册": "注册",
		"使用手机号注册": "使用手机号注册",
		"使用手机号登录": "使用手机号登录",
		"发送验证码": "发送验证码",
		"请输入邮箱地址": "请输入邮箱地址",
		"在此输入密码": "在此输入密码",
		"请输入验证码": "请输入验证码",
		"在此确认密码": "在此确认密码",
		"邮箱不能为空": "邮箱不能为空",
		"邮箱格式不正确": "邮箱格式不正确",
		"邮箱已注册请直接登录": "邮箱已注册请直接登录",
		"邮件发送失败，请稍候重试": "邮件发送失败，请稍候重试",
		"验证码已成功发出": "验证码已成功发出",
		"网络错误，请稍候重试": "网络错误，请稍候重试",
		"登录成功": "登录成功",
		"用户不存在": "用户不存在",
		"账号与密码不匹配": "账号与密码不匹配",
		"密码不能为空": "密码不能为空",
		"确认密码不能为空": "确认密码不能为空",
		"两次密码不一致": "两次密码不一致",
		"验证码不正确": "验证码不正确",
		"验证码已过期": "验证码已过期",
		"注册成功": "注册成功",
		"新会员奖励": "新会员奖励",
		"恭喜您获得了5张Remi 12C抽奖券！": "恭喜您获得了{number}张{product}抽奖券！",
		"热门商品": "热门商品",
		"查看更多商品": "查看更多商品",
		"恭喜中奖会员": "恭喜中奖会员",
		"奖品": "奖品",
		"期数": "期数",
		"奖券编号": "奖券编号",
		"会员邮箱": "会员邮箱",
		"开奖时间": "开奖时间",
		"恭喜会员 x 获得 第x期 x": "恭喜会员 \"{email}\" 获得 第{roundNum}期 \"{productTitle}\"。",
		"登录后继续操作": "登录后继续操作",
		"会员充值": "会员充值",
		"规则说明": "规则说明",
		"请登录后购买": "请登录后购买",
		"会员信息": "会员信息",
		"我的奖券": "我的奖券",
		"加载中...": "加载中...",
		"恭喜您获得了x!": "恭喜您获得了{prize}!",
		"恭喜x获得了x!": "恭喜{winner}获得了{prize}!",
		"n秒后重发": "{n}秒后重发",
		"账户信息": "账户信息",
		"支付方式": "支付方式",
		"充值金额": "充值金额",
		"去支付": "去支付",
		"1、10泰铢可购买一个奖券；": "1、10泰铢可购买一个奖券；",
		"2、同一款产品可以一次购买多个奖劵；": "2、同一款产品可以一次购买多个奖劵；",
		"3、等待商品的奖券售馨后来奖；": "3、等待商品的奖券售馨后来奖；",
		"4、中奖后配合客服确认获奖信息及收货地址；": "4、中奖后配合客服确认获奖信息及收货地址；",
		"5、在家等待奖品到达。": "5、在家等待奖品到达。",
		"立即购买": "立即购买",
		"未填写": "未填写",
		"手机号": "手机号",
		"确定": "确定",
		"规则": "规则",
		"奖券": "奖券",
		"我": "我",
		"使用邮箱登录": "使用邮箱登录",
		"请输入手机号": "请输入手机号",
		"使用邮箱注册": "使用邮箱注册",
		"登录": "登录",
		"未开奖": "未开奖",
		"中奖": "中奖",
		"余额支付成功": "余额支付成功",
		"获取支付链接失败，请稍候重试。": "获取支付链接失败，请稍候重试。",
		"请输入您接收奖品的地址": "请输入您接收奖品的地址",
		"编辑收货地址": "编辑收货地址",
		"支付验证中...": "支付验证中...",
		"请确认购买的奖券数": "请确认购买的奖券数",
		"商品名称：": "商品名称：",
		"奖券数量：": "奖券数量：",
		"验证码不能为空。": "验证码不能为空。",
		"收件人姓名": "收件人姓名",
		"请输入收件人姓名": "请输入收件人姓名",
		"称谓": "称谓",
		"联系电话": "联系电话",
		"房屋号码和/或村庄名": "房屋号码和/或村庄名",
		"街道/巷弄名 （选填）": "街道/巷弄名 （选填）",
		"乡/镇/区": "乡/镇/区",
		"省份": "省份",
		"邮编": "邮编",
		"保存": "保存",
		"保存成功": "保存成功",
		"请稍候重试": "请稍候重试",
		"收件人联系电话不能为空": "收件人联系电话不能为空",
		"房屋号码和/或村庄名不能为空": "房屋号码和/或村庄名不能为空",
		"乡/镇/区不能为空": "乡/镇/区不能为空",
		"省份不能为空": "省份不能为空",
		"邮编不能为空": "邮编不能为空",
		"请输入收件人联系电话": "请输入收件人联系电话",
		"请输入房屋号码和/或村庄名": "请输入房屋号码和/或村庄名",
		"请输入街道/巷弄名": "请输入街道/巷弄名",
		"请输入乡/镇/区": "请输入乡/镇/区",
		"请输入省份": "请输入省份",
		"请输入邮编": "请输入邮编",
		"支付失败！": "支付失败！",
		"支付成功！": "支付成功！",
		"奖券号：": "奖券号：",
		"会员邮箱：": "会员邮箱：",
		"时间：": "时间：",
		"奖品：": "奖品：",
		"好消息！只要在活动期内注册就送价值50泰铢等值的抽奖劵5张！": "好消息！只要在活动期内注册就送价值50泰铢等值的抽奖劵5张！"

	},
	th: {
		"泰会购": 'โชคมันดี',
		"账户余额": 'ยอดเงินในบัญชี',
		"新人大礼包": 'แพ็คเกจของขวัญสำหรับมือใหม่',
		"在这里查看您的奖券": 'ดูตั๋วของคุณที่นี่',
		"邮箱": 'จดหมาย',
		"第几轮": 'รอบ{roundNum}',
		"未开始": "ยังไม่ได้เริ่ม",
		"已开奖": "จับรางวัลแล้ว",
		"中奖号码": "หมายเลขรางวัล",
		"收货地址": "ที่อยู่จัดส่ง",
		"正在开奖": "ลอตเตอรี่กำลังถูกออก",
		"会员登录": "เข้าสู่ระบบสมาชิก",
		"使用手机号登录": "เข้าสู่ระบบโดยใช้หมายเลขโทรศัพท์มือถือ",
		"注册": "ลงทะเบียน",
		"使用手机号注册": "ลงทะเบียนโดยใช้หมายเลขโทรศัพท์มือถือ",
		"发送验证码": "ส่งรหัสยืนยัน",
		"请输入邮箱地址": "กรุณากรอกที่อยู่อีเมล",
		"在此输入密码": "ใส่รหัสผ่านที่นี่",
		"请输入验证码": "กรุณากรอกรหัสยืนยัน",
		"在此确认密码": "ยืนยันรหัสผ่านที่นี่",
		"邮箱格式不正确": "รูปแบบอีเมลไม่ถูกต้อง",
		"邮箱已注册请直接登录": "ที่อยู่อีเมลของคุณได้รับการลงทะเบียนแล้ว กรุณาเข้าสู่ระบบโดยตรง",
		"邮件发送失败，请稍候重试": "การส่งอีเมลล้มเหลว โปรดลองอีกครั้งในภายหลัง",
		"验证码已成功发出": "รหัสยืนยันถูกส่งเรียบร้อยแล้ว",
		"网络错误，请稍候重试": "เครือข่ายเกิดข้อผิดพลาด โปรดลองอีกครั้งในภายหลัง",
		"登录成功": "เข้าสู่ระบบสำเร็จ",
		"用户不存在": "ไม่มีผู้ใช้อยู่",
		"账号与密码不匹配": "บัญชีและรหัสผ่านไม่ตรงกัน",
		"密码不能为空": "รหัสผ่านต้องไม่เว้นว่าง",
		"确认密码不能为空": "ยืนยันรหัสผ่านไม่สามารถเว้นว่างได้",
		"两次密码不一致": "รหัสผ่านสองตัวไม่สอดคล้องกัน",
		"验证码不正确": "รหัสยืนยันไม่ถูกต้อง",
		"验证码已过期": "รหัสยืนยันหมดอายุแล้ว",
		"注册成功": "ความสำเร็จในการลงทะเบียน",
		"新会员奖励": "รางวัลสมาชิกใหม่",
		"恭喜您获得了5张Remi 12C抽奖券！": "ขอแสดงความยินดีที่ถูกรางวัลตั๋วจับฉลาก {product} จำนวน {number} ใบ!",
		"热门商品": "สินค้ายอดนิยม",
		"查看更多商品": "ชมสินค้าเพิ่มเติม",
		"恭喜中奖会员": "ขอแสดงความยินดีกับสมาชิกที่ได้รับรางวัล",
		"奖品": "รางวัล",
		"期数": "ระยะเวลา",
		"奖券编号": "เลขที่ตั๋ว",
		"会员邮箱": "อีเมล์สมาชิก",
		"开奖时间": "เวลาออกสลากกินแบ่ง",
		"恭喜会员 x 获得 第x期 x": "ขอแสดงความยินดีกับสมาชิก \"{email}\" ที่ได้รับ \"{productTitle}\" ฉบับ {roundNum}",
		"登录后继续操作": "เข้าสู่ระบบเพื่อดำเนินการต่อ",
		"会员充值": "เติมเงินสมาชิก",
		"规则说明": "คำอธิบายกฎ",
		"请登录后购买": "กรุณาเข้าสู่ระบบเพื่อซื้อ",
		"会员信息": "ข้อมูลสมาชิก",
		"我的奖券": "ตั๋วลอตเตอรีของฉัน",
		"加载中...": "กำลังโหลด...",
		"恭喜您获得了x!": "ขอแสดงความยินดีที่ได้รับ {prize}!",
		"恭喜x获得了x!": "ขอแสดงความยินดีกับ {winner} ที่ได้รับ {prize}!",
		"账户信息": "ข้อมูลเกี่ยวกับบัญชี",
		"支付方式": "วิธีการชำระเงิน",
		"充值金额": "จำนวนการเติมเงิน",
		"去支付": "จ่าย",
		"1、10泰铢可购买一个奖券；": "1. 10 บาท สามารถซื้อสลากได้คะ",
		"2、同一款产品可以一次购买多个奖劵；": "2. คุณสามารถซื้อคูปองหลายใบสำหรับผลิตภัณฑ์เดียวกันในคราวเดียวคะ",
		"3、等待商品的奖券售馨后来奖；": "3. รอคูปองสินค้าจำหน่ายและรับรางวัลในภายหลังคะ",
		"4、中奖后配合客服确认获奖信息及收货地址；": "4、หลังจากประกาศถูกรับรางวัลแล้ว ขอให้ร่วมมือกับฝ่ายบริการลูกค้าเพื่อยืนยันข้อมูลที่ประกาศถูกหรับรางวัลและที่อยู่สำหรับจัดส่ง แล้วรอรับของรางวัลนะคะ",
		"5、在家等待奖品到达；": "5.รอรับของรางวัลถึงบ้าน",
		"立即购买": "ซื้อเลย",
		"未填写": "ยังไม่สำเร็จ",
		"手机号": "หมายเลขโทรศัพท์",
		"确定": "กดซื้อ",
		"规则": "กฎ",
		"奖券": "ตั๋วลอตเตอรี",
		"我": "ฉัน",
		"使用邮箱登录": "เข้าสู่ระบบโดยใช้อีเมล",
		"请输入手机号": "กรุณากรอกหมายเลขโทรศัพท์",
		"使用邮箱注册": "ลงทะเบียนโดยใช้อีเมล์",
		"登录": "เข้าสู่ระบบ",
		"未开奖": "ยังไม่ได้วาดเลย",
		"中奖": "ชนะ",
		"余额支付成功": "ชำระยอดคงเหลือสำเร็จ",
		"获取支付链接失败，请稍候重试。": "รับลิงก์การชำระเงินไม่สำเร็จ โปรดลองอีกครั้งในภายหลัง",
		"请输入您接收奖品的地址": "กรุณากรอกที่อยู่ที่คุณจะรับรางวัล",
		"编辑收货地址": "แก้ไขที่อยู่จัดส่ง",
		"支付验证中...": "อยู่ระหว่างการตรวจสอบการชำระเงิน...",
		"请确认购买的奖券数": "โปรดยืนยันจำนวนตั๋วที่ซื้อ",
		"商品名称：": "ชื่อผลิตภัณฑ์:",
		"奖券数量：": "จำนวนตั๋วลอตเตอรี:",
		"n秒后重发": "ส่งอีกครั้งหลังจากผ่านไป {n} วินาที",
		"验证码不能为空。": "ต้องกรอกรหัสยืนยัน",
		"收件人姓名": "ชื่อผู้รับ",
		"请输入收件人姓名": "กรุณากรอกชื่อผู้รับ",
		"称谓": "ชื่อ",
		"联系电话": "เบอร์ติดต่อ",
		"房屋号码和/或村庄名": "บ้านเลขที่ และ/หรือ ชื่อหมู่บ้าน",
		"街道/巷弄名 （选填）": "ชื่อถนน/ซอย (ไม่บังคับ)",
		"乡/镇/区": "เขตการปกครอง/เมือง/เขต",
		"省份": "จังหวัด",
		"邮编": "รหัสไปรษณีย์",
		"保存": "บันทึก",
		"保存成功": "บันทึกเรียบร้อยแล้ว",
		"请稍候重试": "กรุณาลองใหม่อีกครั้งในภายหลัง",
		"收件人姓名不能为空": "ชื่อผู้รับต้องไม่เว้นว่าง",
		"收件人联系电话不能为空": "หมายเลขติดต่อของผู้รับต้องไม่เว้นว่าง",
		"房屋号码和/或村庄名不能为空": "หมายเลขบ้านและ/หรือชื่อหมู่บ้านต้องไม่เว้นว่าง",
		"乡/镇/区不能为空": "เขตการปกครอง/เมือง/เขตไม่สามารถเว้นว่างได้",
		"省份不能为空": "จังหวัดต้องไม่เว้นว่าง",
		"邮编不能为空": "รหัสไปรษณีย์ต้องไม่เว้นว่าง",
		"请输入收件人联系电话": "กรุณากรอกหมายเลขติดต่อของผู้รับ",
		"请输入房屋号码和/或村庄名": "กรุณากรอกบ้านเลขที่ และ/หรือ ชื่อหมู่บ้าน",
		"请输入街道/巷弄名": "กรุณากรอกชื่อถนน/ซอย",
		"请输入乡/镇/区": "กรุณาระบุเมือง/เมือง/เขต",
		"请输入省份": "กรุณาระบุจังหวัด",
		"请输入邮编": "กรุณากรอกรหัสไปรษณีย์",
		"支付失败！": "การชำระเงินล้มเหลว!",
		"支付成功！": "ชำระเงินสำเร็จ!",
		"奖券号：": "หมายเลขลอตเตอรี่:",
		"会员邮箱：": "อีเมล์สมาชิก:",
		"时间：": "เวลา:",
		"奖品：": "รางวัล:",
		"好消息！只要在活动期内注册就送价值50泰铢等值的抽奖劵5张！": "ข่าวดี! เพียงลงทะเบียนภายในระยะเวลากิจกรรม จะได้รับ ตั๋วจับรางวัล 5 ใบ มูลค่า 50 บาท!"

	}
}

// 创建 i18n 实例并传入配置
const i18n = createI18n({
	locale: 'th', // 设置默认语言环境
	fallbackLocale: 'zh', // 设置备用语言环境
	messages, // 设置本地化信息
})

export default i18n;